import React, { Fragment, Component } from 'react';
import { withLastLocation } from "react-router-last-location";
import BulkUploadWrapper from '../dashboard/organization-roles-users/BulkUploadWrapper';
import HeaderCard from '../../components/Dasboard_Components/HeaderCard';
import { DASHBOARDROUTES } from '../../helpers/constants/routes';
import Button from '../ekyc/rsa/components/Button';
import { ButtonContainer } from '../dashboard/dashboardLayoutStyle';

class FetchUnicornImage extends Component {


  triggerUploadAction=()=>{}

  render() {
    return (
      <Fragment>
      
        <BulkUploadWrapper
          headerText={"Fetch Unicorn Image"}
          downloadParam={`&sub_project=${"id"}`}
          processAction={this.triggerUploadAction}
          enableSuccessAlert={false}
          uploadSuccessText={"Upload Process Initiated"}
          customElement={null}
          bulkUploadType={"bulk_upload_unicorn_utility"}
          fetchUnicorn={true}

        />
      </Fragment>
    );
  }
}

export default withLastLocation(FetchUnicornImage);
