import React, { useMemo, useState, useEffect, useRef } from "react";
import "./CollectedBills.css";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";

import { Button } from "../../../components/Button";
import { ReactComponent as ExcelIcon } from "../../../components/icons/excel.svg";
import moment from "moment";
import { useTable } from "react-table";
import { CustomNextArrow, CustomPrevArrow } from "./CustomArrow";
import getCollectedBills from "../../../actions/creation/getCollectedBills";
import ImageViewer from "./imageCollectedBills";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import RadioButtonUncheckedIcon from "@material-ui/icons/RadioButtonUnchecked";
import RadioButtonCheckedIcon from "@material-ui/icons/RadioButtonChecked"; // Filled icon for selected button
import DeleteIcon from "@material-ui/icons/Delete"; // Import the Delete icon
import getAssignSku from "../../../actions/creation/getAssignSku";
import CustomDropdown from "./CollectedBillsDropdown";
import Add from "@material-ui/icons/Add";
import CircularProgress from "@material-ui/core/CircularProgress";
import getGstAndPanDetails from "../../../actions/creation/getGstAndPanDetails";
import { bindActionCreators } from "redux";
// import { Dialog, DialogActions, DialogContent, DialogTitle, TextField } from "@material-ui/core";

import triggerNotification from "../../../actions/triggerNotification";
import { connect } from "react-redux";
import ImageIcon from "@material-ui/icons/Image";
import EditIcon from "@material-ui/icons/Edit";

import { DASHBOARDROUTES } from "../../../helpers/constants/routes";
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  TextField
} from "@material-ui/core"; // Import Material-UI components from core
import { triggerSimpleAjax } from "../../../helpers/httpHelper";
import { DELETE_COLLECTED_BILLS_SKU } from "../../../helpers/constants/apiUrl";
import createSkuCollectedBills from "../../../actions/creation/createSKUBills";
import getAddNewSkuData from "../../../actions/creation/getNewSkuData";
import createaddskuexcel from "../../../actions/creation/createAddNewSkuExcel";
import { black100, black200, black300, black500, blue300 } from "../../../theme/colors";
import { AppConfig } from "../../../app-config";
import { green } from "@material-ui/core/colors";
import { ArrowBack, ArrowForward } from "@material-ui/icons";
import updateInvoiceNumber from "../../../actions/creation/updateInvoiceNumber";

let getcategoryDetailsStaticQueryParams =
  "&no-pagination&exclude_retailers=true";

const CollectedBills = (props) => {
  const [selectedCard, setSelectedCard] = useState(0);
  const [isExpanded, setIsExpanded] = useState(true);
  const [loading, setLoading] = useState(true);
  const [loadingSku, setLoadingSku] = useState(true);
  const [cardDataForSKus, setCardDataForSKus] = useState();
  const [cardTexts, setCardTexts] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [skuName, setSkuName] = useState([]);
  const [savedCard, setSavedCard] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [open, setOpen] = useState(false);
  const [results, setResult] = useState([]);
  const [refreshData, setRefreshData] = useState(false); 
  const [newAddedSKu, setNewAddedSKu] = useState([]);
  const [selectedSkuID, setSelectedSkuID] = useState(null);
  const [isNewSKUDelete, setIsNewSKUDelete] = useState(false);
  const [selectedRowIndex, setSelectedRowIndex] = useState(null);
  const [hasNewSku, setHasNewSku] = useState(false);
  const [editLoader, setEditLoader] = useState(false);
  const [localInvoiceHeaders, setLocalInvoiceHeaders] = useState([]);
  const [errorIndexes, setErrorIndexes] = useState([]);
  const [errorMessages, setErrorMessages] = useState({});
  const [invoiceNo, setInvoiceNo] = useState(""); 
  const [invoiceDate, setInvoiceDate] = useState("");
  const [error, setError] = useState(false);



  const cardContainerRef = useRef(null);
  const today = new Date().toISOString().split("T")[0];

  const enableEditAnswers = props?.enableEditAnswers;

  const scroll = (scrollOffset) => {
    if (cardContainerRef.current) {
      cardContainerRef.current.scrollLeft += scrollOffset;
    }
  };

  useEffect(() => {
   
    fetchData();
  }, [refreshData]);

   async function fetchData() {
    
      try {
        const req = props.outletDetails.workflow_object;
        setLoading(true);
        const response = await getCollectedBills(req);        
        setTableData([])
        setCardDataForSKus(response);
        setCardTexts(
          response?.results.map(
            (card) => `${card.invoice_date}, ${card.invoice_no}`
          ) || []
        );
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    }

        
  const handleChange = (e) => {
    setInvoiceNo(e.target.value);
    setError(e.target.value.trim() === ""); // Set error if the field is empty
  };

  const handleBlur = () => {
    setError(invoiceNo.trim() === ""); // Validate on blur
  };

  const handleEditClick = (index) => {
    setOpen(true);
  };


  const handleSave = async() => {
    const { notificationHandler } = props;
 
    const currentCardData= cardDataForSKus?.results[selectedCard];
    const payload = {
      invoice_no: invoiceNo,
      invoice_date: invoiceDate,
      sub_project:currentCardData.sub_project,
      workflow_object:currentCardData.workflow_object,
      simplifiedinvoiceheaderanswer:[],
      simplifiedinvoiceskudata:[],
      is_edit_invoicenumber:true,
      is_edit:false,
      is_edit_simplifiedinvoiceskudata:false,
      is_edit_simplifiedinvoiceheaderanswer:false,
      id:currentCardData.id,
    };
 
    try {
     const response= await updateInvoiceNumber(
        currentCardData.id, payload
      );
      notificationHandler({
        messageText: `Invoice number and date saved successfully.`,
        status: "SUCCESS",
      });
      setRefreshData((prev) => !prev);
    } catch (error) {
      setInvoiceNo(cardDataForSKus?.results[selectedCard]?.invoice_no);
 
      notificationHandler({
        messageText: error.message,
        status: "FAILED",
      });
    } finally {
      setEditLoader(false);
    }
 
    setOpen(false);
  };
 


  
  

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (selectedCard === "new-sku") {
      const req = props.outletDetails.workflow_object;
      async function fetchNewSKuData() {
        try {
          // const req = props.outletDetails.workflow_object;
          setLoading(true);
          const response = await getAddNewSkuData(req);
          setNewAddedSKu(response);
          setLoading(false);
        } catch (error) {
          setLoading(false);
        }
      }
      fetchNewSKuData();
    }
  }, [selectedCard]);

  useEffect(() => {
    const subProjectSlugId = props.outletDetails.sub_project.slug;
    const req = `?sub_project__slug=${subProjectSlugId}${getcategoryDetailsStaticQueryParams}`;
    setLoadingSku(true);
    getAssignSku(req).then((response) => {
      const { results } = response;
      const skuNames = results.map((name) => name.sku_name);
      const skuData = results.map((item) => ({
        sku_name: item.sku_name,
        sku: item.sku,
      }));
      setLoadingSku(false);
      setResult(skuData);
      setSkuName(skuNames);
    });
  }, [props.outletDetails.sub_project.slug]);

  const handleCardClick = (index) => {
    setSelectedCard(index);
    setErrorIndexes([]);
    setErrorMessages({});
  };

  const toggleExpandCollapse = () => {
    setIsExpanded(!isExpanded);
  };

  const handleOpenDialog = (skuID, rowIndex, isNewSKUDelete) => {
    
    setIsNewSKUDelete(isNewSKUDelete)
    setSelectedSkuID(skuID);
    setSelectedRowIndex(rowIndex);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const confirmDelete = () => {
    handleDeleteRow(); // Call your existing delete function
    setOpenDialog(false); // Close the dialog
  };

  const checkForNewSkus = () => {
    const newSkuExists = tableData.some(
      (row) =>
        (row.sku_name &&
          skuName.length > 0 &&
          !skuName.includes(row.sku_name)) ||
        (row.add_on_sku &&
          skuName.length > 0 &&
          !skuName.includes(row.add_on_sku)) // Check for both sku_name and add_on_sku, and ensure skuName is not empty
    );

    setHasNewSku(newSkuExists);
  };

  // Call this function whenever tableData changes
  useEffect(() => {        
    checkForNewSkus();

     

  }, [tableData]);

  const [validationErrors, setValidationErrors] = useState([]);

  const validateTableData = () => {
    const errors = [];
    const sku = cardDataForSKus?.results[selectedCard];

    const currentskuData = sku.simplifiedinvoiceskudata?.map((skulist) => {
      return skulist.simplifiedinvoiceskudetails;
    });

  
    tableData.forEach((row, rowIndex) => {
      console.log("tablevalue===>>>",tableData);


      const sku_name = row.sku_name;

      // Check if sku_name is empty
      if (!sku_name || sku_name.trim() === "") {
        errors.push({
          rowIndex,
          columnId: "sku_name",
          message: `SKU Name cannot be empty.`,
        });
        // return; // Skip further validation for this row
      }

      // const isValidSkuName = results.some((item) => item.sku_name === sku_name);
      // if (!isValidSkuName) {
      //   errors.push({
      //     rowIndex,
      //     columnId: "sku_name",
      //     message: `Invalid SKU Name. Please select from the dropdown.`,
      //   });
      // }
      Object.entries(row).forEach(([columnId, value]) => {
        // let extractedData = null;
        let extractedData = null;

        if (typeof value === 'object') {
              extractedData = {
                sku_template_field: value.sku_template_field,
                sku_template_field_name: value.headerName,
                sku_template_field_type: value.type,
                validations: value.validations,
              };
        }

        // Find the corresponding validation details for the columnId
        // currentskuData.forEach((innerArray) => {
        //   innerArray.forEach((item) => {
        //     if (item.sku_template_field.toString() === columnId) {
        //       extractedData = {
        //         sku_template_field: item.sku_template_field,
        //         sku_template_field_name: item.sku_template_field_name,
        //         sku_template_field_type: item.sku_template_field_type,
        //         validations: item.validations,
        //       };
        //     }
        //   });
        // });
        
        if (extractedData) {
      console.log("extractedData===>>>",tableData);

          const { validations, sku_template_field_type } = extractedData;

          console.log("extractedData--->>>",extractedData);

          // Trim the input value
          const trimmedValue =
            value.value !== null && value.value !== undefined
              ? String(value.value).trim()
              : "";

          // Check if field is empty and mandatory
          if (trimmedValue === "") {
            errors.push({
              rowIndex,
              columnId,
              message: `${extractedData.sku_template_field_name} cannot be empty.`,
            });
            return; // Skip further validation if empty
          }

          switch (sku_template_field_type) {
            
            case "text":
              // Validate text field length
              if (
                validations.min_length &&
                trimmedValue.length < validations.min_length
              ) {
                errors.push({
                  rowIndex,
                  columnId,
                  message: `${extractedData.sku_template_field_name} must be at least ${validations.min_length} characters.`,
                });
              }
              if (
                validations.max_length &&
                trimmedValue.length > validations.max_length
              ) {
                errors.push({
                  rowIndex,
                  columnId,
                  message: `${extractedData.sku_template_field_name} must not exceed ${validations.max_length} characters.`,
                });
              }
              break;

            case "number":
              const numericValue = parseFloat(trimmedValue);
              if (isNaN(numericValue)) {
                errors.push({
                  rowIndex,
                  columnId,
                  message: `${extractedData.sku_template_field_name} must be a valid number.`,
                });
              } else {
                // Validate number limits
                if (
                  validations.lower_limit &&
                  numericValue < parseFloat(validations.lower_limit)
                ) {
                  errors.push({
                    rowIndex,
                    columnId,
                    message: `${extractedData.sku_template_field_name} must be greater than or equal to ${validations.lower_limit}.`,
                  });
                }
                if (
                  validations.upper_limit &&
                  numericValue > parseFloat(validations.upper_limit)
                ) {
                  errors.push({
                    rowIndex,
                    columnId,
                    message: `${extractedData.sku_template_field_name} must be less than or equal to ${validations.upper_limit}.`,
                  });
                }
                if (!validations.allow_decimals && trimmedValue.includes(".")) {
                  errors.push({
                    rowIndex,
                    columnId,
                    message: `${extractedData.sku_template_field_name} must be an integer.`,
                  });
                }
              }
              break;

            case "dropdown":
              if (
                validations.choices &&
                !validations.choices.split(";").includes(trimmedValue)
              ) {
                errors.push({
                  rowIndex,
                  columnId,
                  message: `${extractedData.sku_template_field_name} must be one of the allowed choices: ${validations.choices}.`,
                });
              }
              break;

            case "date":
              const dateValue = Date.parse(trimmedValue);
              if (isNaN(dateValue)) {
                errors.push({
                  rowIndex,
                  columnId,
                  message: `${extractedData.sku_template_field_name} must be a valid date.`,
                });
              } else {
                const parsedDate = new Date(dateValue);
                if (
                  validations.lower_than &&
                  parsedDate >= new Date(validations.lower_than)
                ) {
                  errors.push({
                    rowIndex,
                    columnId,
                    message: `${extractedData.sku_template_field_name} must be earlier than ${validations.lower_than}.`,
                  });
                }
                if (
                  validations.greater_than &&
                  parsedDate <= new Date(validations.greater_than)
                ) {
                  errors.push({
                    rowIndex,
                    columnId,
                    message: `${extractedData.sku_template_field_name} must be later than ${validations.greater_than}.`,
                  });
                }
              }
              break;

            case "time":
              const timeValue = Date.parse(`1970-01-01T${trimmedValue}Z`);
              if (isNaN(timeValue)) {
                errors.push({
                  rowIndex,
                  columnId,
                  message: `${extractedData.sku_template_field_name} must be a valid time.`,
                });
              } else {
                const parsedTime = new Date(timeValue);
                if (
                  validations.lower_than &&
                  parsedTime >=
                    new Date(`1970-01-01T${validations.lower_than}Z`)
                ) {
                  errors.push({
                    rowIndex,
                    columnId,
                    message: `${extractedData.sku_template_field_name} must be earlier than ${validations.lower_than}.`,
                  });
                }
                if (
                  validations.greater_than &&
                  parsedTime <=
                    new Date(`1970-01-01T${validations.greater_than}Z`)
                ) {
                  errors.push({
                    rowIndex,
                    columnId,
                    message: `${extractedData.sku_template_field_name} must be later than ${validations.greater_than}.`,
                  });
                }
              }
              break;

            default:
              console.warn(
                `Unhandled type "${sku_template_field_type}" for field: ${extractedData.sku_template_field_name}`
              );
              break;
          }
        } else {
          console.warn(
            `No matching field found for columnId: ${columnId} in currentskuData`
          );
        }
      });
    });


    setValidationErrors(errors);

    return errors.length === 0;
  };

  const handleSaveNext = async () => {
    const isValid = validateTableData();


    if (!isValid) {
      return;
    }
    setSavedCard(selectedCard);
    setEditLoader(true);

    const currentCardData = cardDataForSKus?.results[selectedCard];

    // Perform validation: Check if any required field is empty
    const hasEmptyFields = tableData.some((row, rowIndex) => {
      const emptyFields = Object.entries(row).filter(([key, value]) => {
        return value === "" || value === null || value === undefined;
      });

      return false;
    });

    if (hasEmptyFields) {
      // Stop further processing if validation fails
      setEditLoader(false); // Hide loader
      return;
    }

    setEditLoader(true);
    const tableDataMap = tableData.reduce((map, row) => {
      map[row.id] = row; // Assuming `id` is unique, else use `sku_name`
      return map;
    }, {});

    let isOuterEdit = false;
    let isOuterSkuDataEdit = false;

    const getFieldName = (key) => {
      const fieldDetail = currentCardData.assigned_sku_template_field.find(
        (field) => field.id === parseInt(key)
      );

      return fieldDetail ? fieldDetail.name : key; // Fallback to key if name is not found
    };

    const getFieldType = (key) => {
      const fieldDetail = currentCardData.assigned_sku_template_field.find(
        (field) => field.id === parseInt(key)
      );
      return fieldDetail ? fieldDetail.type : key; // Fallback to key if type is not found
    };

    const updatedSkuData = currentCardData.simplifiedinvoiceskudata?.map(
      (skuItem) => {
        const matchingRow = tableDataMap[skuItem.id]; // Match by `id` instead of `sku_name`
        let isSkuDataEdited = false;
        const updatedSkuDetails = skuItem.simplifiedinvoiceskudetails.map(
          (skuDetail, index) => {
            const tableValue = matchingRow
              ? matchingRow[skuDetail.sku_template_field]
              : undefined;

            // Preserve the current single_input if tableValue is undefined

            if (tableValue === undefined) {
              console.log("test me one");
              return {
                ...skuDetail,
                is_edit: isSkuDataEdited,
                single_input: skuDetail.single_input.value,
              };
            }

            // If the tableValue is an empty string, preserve the existing single_input
            if (tableValue === "") {
              console.log("test me two");

              return {
                ...skuDetail,
                is_edit: true,
                single_input: "",
              };
            }

            // If the tableValue is different from the current single_input, update it
            if (tableValue !== skuDetail.single_input) {
              console.log("test me three",tableValue);

              isSkuDataEdited = true;
              isOuterEdit = true;
              return {
                ...skuDetail,
                is_edit: true,
                single_input: tableValue.value,
              };
            }

            return skuDetail;
          }
        );

        // Handle additional fields from currentCardData.assigned_sku_template_field
        currentCardData.assigned_sku_template_field.forEach((field) => {
          const fieldExists = skuItem.simplifiedinvoiceskudetails.some(
            (detail) => detail.sku_template_field === field.id
          );

          if (!fieldExists) {
            console.log("test me four");

            const tableValue = matchingRow ? matchingRow[field.id] : "";
            updatedSkuDetails.push({
              id: null,
              sku_template_field: field.id,
              sku_template_field_type: field.type,
              sku_template_field_name: field.name,
              single_input: tableValue || "",
              simplifiedinvoiceskuimageanswer: [],
              is_edit: true,
            });

            isSkuDataEdited = true;
            isOuterEdit = true;
          }
        });

        if (isSkuDataEdited) {
          isOuterSkuDataEdit = true;
        }

        const updatedSku =
          matchingRow && matchingRow.sku !== undefined
            ? matchingRow.sku
            : skuItem.sku; // Use the SKU from matchingRow or fallback

        const isSkuNameEdited =
          matchingRow && matchingRow.sku_name !== skuItem.sku_name;

        return {
          ...skuItem,
          simplifiedinvoiceskudetails: updatedSkuDetails, // Keep the updated details intact
          sku_name: matchingRow ? matchingRow.sku_name : skuItem.sku_name,
          sku: updatedSku, // Include `sku`
          is_edit: isSkuNameEdited || isSkuDataEdited,
          is_edit_simplifiedinvoiceskudetails: isSkuDataEdited,
        };
      }
    );

    // Check if updatedSkuData has changed from the original data
    const hasChanges = !currentCardData.simplifiedinvoiceskudata.every(
      (originalSkuItem, index) => {
        const updatedSkuItem = updatedSkuData[index];
        return (
          originalSkuItem.sku === updatedSkuItem.sku &&
          originalSkuItem.sku_name === updatedSkuItem.sku_name &&
          JSON.stringify(originalSkuItem.simplifiedinvoiceskudetails) ===
            JSON.stringify(updatedSkuItem.simplifiedinvoiceskudetails)
        );
      }
    );

    // If changes are detected, set both edit flags to true
    if (hasChanges) {
      updatedSkuData.forEach((skuItem) => {
        skuItem.is_edit = true;
        skuItem.is_edit_simplifiedinvoiceskudetails = true;
      });
      isOuterSkuDataEdit = true; // Set global edit flag
    }

    // Now, updatedSkuData is ready to be used

    const newAddOnSkuRows = currentCardData.simplifiedinvoiceaddonsku?.map(
      (addOnSkuItem) => {
        const matchingRow = tableDataMap[addOnSkuItem.id]; // Match by `id` instead of `sku_name`
        const updatedDetails = addOnSkuItem.simplifiedinvoiceskudetails.map(
          (skuDetail) => {
            const tableValue = matchingRow
              ? matchingRow[skuDetail.sku_template_field]
              : undefined;

            // Preserve the current single_input if tableValue is undefined
            if (tableValue === undefined) {
              console.log("test me five");

              return {
                ...skuDetail,
                single_input: skuDetail.single_input.value,
              };
            }

            // If the tableValue is an empty string, preserve the existing single_input
            if (tableValue === "") {
              console.log("test me six");
              return {
                ...skuDetail,
                single_input: "", // Reset single_input if value is empty
              };
            }

            // If the tableValue is different from the current single_input, update it
            if (tableValue !== skuDetail.single_input) {
              console.log("test me seven");
              return {
                ...skuDetail,
                single_input: tableValue, // Update with the new table value
              };
            }

            return skuDetail; // Return unchanged skuDetail if no updates are necessary
          }
        );

        currentCardData.assigned_sku_template_field.forEach((field) => {
          const fieldExists = updatedDetails.some(
            (detail) => detail.sku_template_field === field.id
          );

          if (!fieldExists) {
            console.log("test me eight");
            const tableValue =
              matchingRow && matchingRow[field.id] !== undefined
                ? matchingRow[field.id]
                : ""; // Use empty string as fallback if no value is found

            updatedDetails.push({
              sku_template_field: field.id,
              sku_template_field_type: field.type,
              sku_template_field_name: field.name,
              single_input: tableValue || "", // Fallback to empty string if value not found
              simplifiedinvoiceskuimageanswer: [],
            });
          }
        });

        return {
          id: addOnSkuItem.id,
          sku_name: addOnSkuItem.add_on_sku || "", // Use add_on_sku as sku_name
          sku: matchingRow ? matchingRow.sku : "", // Use SKU from matchingRow or fallback
          simplifiedinvoiceskudetails: updatedDetails, // Use updated details
        };
      }
    );

    const newRows = tableData
      .filter((row) => row.isNew)
      .map((row) => {
        const matchedSku = results.find(
          (result) => result.sku_name === row.sku_name
        );
        console.log("test me nine");

        return {
          sku_name: row.sku_name || "",
          sku: matchedSku ? matchedSku.sku : "",
          simplifiedinvoiceskudetails: Object.entries(row)
            .filter(([key]) => key !== "id" && key !== "isNew")
            .map(([key, value]) => ({
              sku_template_field: key,
              sku_template_field_name: getFieldName(key),
              sku_template_field_type: getFieldType(key),
              single_input: value.value,
              simplifiedinvoiceskuimageanswer: [],
            })),
        };
      });

    const payload = {
      ...currentCardData,
      simplifiedinvoiceskudata: updatedSkuData,
      new_simplifiedinvoiceskudata: [...newRows, ...newAddOnSkuRows],
      is_edit: true,
      is_edit_simplifiedinvoiceskudata: true,
    };
    
    try {
      const response = await createSkuCollectedBills(
        currentCardData.id,
        payload
      );
      setRefreshData((prev) => !prev);
    } catch (error) {
    } finally {
      setEditLoader(false);
    }

    if (selectedCard < cardDataForSKus?.results.length - 1) {
      setSelectedCard(selectedCard + 1);
    } else {
      props.setIsSliderComplete(true);
    }
  };

  const columns = useMemo(() => {
    const firstSkuData =
    cardDataForSKus?.results[selectedCard]?.assigned_sku_template_field || [];
    setInvoiceNo(cardDataForSKus?.results[selectedCard]?.invoice_no);
    setInvoiceDate(cardDataForSKus?.results[selectedCard]?.invoice_date)      
    const seenHeaders = new Set();
    return [
      {
        Header: "SKU Name", // Always have "SKU Name" as the first column
        accessor: "sku_name",
        type :"text",
        otherData : null
      },
      // Directly map over firstSkuData if it's an array
      ...(firstSkuData?.map((detail) => {
        
        if (!detail) return null; // Handle undefined detail
        // Using `name` (or other relevant field) as the unique header
        let uniqueHeader = detail.name || `Detail ${detail.id}`;
        let accessor = detail.id?.toString() || "undefined_id";
        let type = detail.type || "text";
        let otherData = detail.validations ;

        // skuOtherData.find(item => item.sku_template_field === detail.id);
        // if (skuOtherData.find(item => item.sku_template_field === detail.id) != undefined) {
        //   otherData = skuOtherData.find(item => item.sku_template_field === detail.id);
        // }

        // Handle duplicate accessor keys
        let index = 1;
        while (seenHeaders.has(accessor)) {
          accessor = `${uniqueHeader}_${index}`;
          index++;
        }
        seenHeaders.add(accessor);

        return {
          Header: uniqueHeader, // Display field name as header
          accessor: accessor, // Key for accessing this field's value
          type : type,
          validations: otherData
        };
      }) || []),
    ];
  }, [selectedCard, cardDataForSKus]);

  const data = useMemo(() => {
    const currentResults = cardDataForSKus?.results[selectedCard] || {};


    // Function to map data for both sku types, with conditional key for add_on_sku and including the id
    const mapSkuData = (skuDataArray, invoiceDate, isAddon = false) =>

      skuDataArray?.map((skuData) => ({
        id: skuData.id, // Include the id field
        sku: skuData.sku,
        [isAddon ? "add_on_sku" : "sku_name"]: isAddon
          ? skuData.add_on_sku
          : skuData.sku_name,
        invoice_date: invoiceDate,
        ...skuData.simplifiedinvoiceskudetails.reduce(
          (acc, detail) => ({
            ...acc,
            [detail.sku_template_field.toString()]: {
              headerName:detail.sku_template_field_name,
              value: detail.single_input,
              type: detail.sku_template_field_type,
              validations: detail.validations,
            },
          }),
          {}
        ),
      })) || [];

    // Map both simplifiedinvoiceskudata and simplifiedinvoiceaddonsku
    const currentSkuData = mapSkuData(
      currentResults.simplifiedinvoiceskudata,
      currentResults.invoice_date
    );

    const currentAddonSkuData = mapSkuData(
      currentResults.simplifiedinvoiceaddonsku,
      currentResults.invoice_date,
      true
    );

    // Combine both data sets
    const combinedData = [...currentSkuData, ...currentAddonSkuData];

    const emptyRow = {};
    columns.forEach((column) => {
      emptyRow[column.accessor] = ""; // Set each key to an empty string
    });

    return combinedData;
  }, [selectedCard, columns, cardDataForSKus]);

  const addEmptyRow = () => {
    const emptyRow = {
      id: Date.now(), // Unique identifier using timestamp or you can use a UUID library
      isNew: true, // Flag to indicate it's a new row
    };

    columns.forEach((column) => {
      if (column.validations != null) {
        emptyRow[column.accessor] = {
          headerName :column.Header, 
          value : "",
          type : column.type,
          validations :column?.validations || null
  
        }; // Set each key to an empty string  
      }else{
        emptyRow[column.accessor] = ""
      }
    });

    console.log("columns---->>>",emptyRow);

    setTableData((prevData) => [...prevData, emptyRow]);
  };

  useEffect(() => {
    // Initialize the tableData when data changes

    setTableData(data);
  }, [data]);
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({
      columns,
      data: tableData,
    });

  const handleInputChange = (event, rowIndex, columnId, value, type) => {
    const newValue = event.target.value;

    setTableData((prevData) => {
      const updatedData = [...prevData];

      updatedData[rowIndex] = {
        ...updatedData[rowIndex],
        [columnId]: {
          ...updatedData[rowIndex][columnId],
          value: newValue,
        },
      };

      // updatedData[rowIndex] = {
      //   ...updatedData[rowIndex],
      //   [columnId]: newValue,
      // };

      return updatedData;
    });
  };

  const newAddedSKuTest = newAddedSKu?.results ?? [];

  const sliderSettings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    arrows: true,
    nextArrow: <CustomNextArrow />,
    prevArrow: <CustomPrevArrow />,
  };

  const getInvoiceHeaders = (data, index) => {
    const simplifiedInvoiceHeaderAnswer =
      data?.results?.[index]?.simplifiedinvoiceheaderanswer;

    if (!Array.isArray(simplifiedInvoiceHeaderAnswer)) {
      return [];
    }


    return simplifiedInvoiceHeaderAnswer.map((item) => ({
      header: item?.invoice_header_name,
      singleInput: item?.single_input,
      invoiceHeaderValidation: item.validations,
      invoiceHeadersTypes: item.invoice_header_type,
      is_mandatory:item.is_mandatory,
      type: item?.invoice_header_type,
      simplifiedinvoiceheaderanswerdetails: Array.isArray(
        item?.simplifiedinvoiceheaderanswerdetails
      )
        ? item.simplifiedinvoiceheaderanswerdetails.map((detail) => ({
            gst_legal_name: detail?.gst_legal_name,
            gst_registration_status: detail?.gst_registration_status,
            gst_trade_name: detail?.gst_trade_name,
            pan_holder_name: detail?.pan_holder_name,
          }))
        : [],
    }));
  };

  const invoiceHeaders = useMemo(
    () => getInvoiceHeaders(cardDataForSKus, selectedCard),
    [cardDataForSKus, selectedCard]
  );


  useEffect(() => {
    setLocalInvoiceHeaders([...invoiceHeaders]);
  }, [invoiceHeaders]);

  const handleSingleInputChange = (e, index) => {
    const value = e.target.value;

    let formattedValue = value;
    if (e.target.type === "date") {
      const inputDate = new Date(value);
      formattedValue = inputDate.toISOString().split("T")[0];
    }

    if (e.target.type === "time") {
      const timeToCheck = moment(value, "HH:mm").format("YYYY-MM-DD HH:mm:ss");
      const timeMoment = moment(timeToCheck, "YYYY-MM-DD HH:mm:ss");

      const startTime = moment("10:00", "HH:mm").format("YYYY-MM-DD HH:mm:ss");
      const startMoment = moment(startTime, "YYYY-MM-DD HH:mm:ss");

      const endTime = moment("19:00", "HH:mm").format("YYYY-MM-DD HH:mm:ss");
      const endMoment = moment(endTime, "YYYY-MM-DD HH:mm:ss");
      formattedValue = timeMoment.format("HH:mm:ss");
    }

    const updatedHeaders = [...localInvoiceHeaders];
    updatedHeaders[index].singleInput = formattedValue;
    if (updatedHeaders[index].type === "gst_number") {
      updatedHeaders[index].isVerified = false; // Set to "Validate"

      updatedHeaders[index].simplifiedinvoiceheaderanswerdetails =
        updatedHeaders[index].simplifiedinvoiceheaderanswerdetails.map(
          (item) => ({
            ...item,
            gst_legal_name: "",
            gst_trade_name: "",
            gst_registration_status: "",
          })
        );
    } else if (updatedHeaders[index].type === "pan_number") {
      updatedHeaders[index].isVerified = false; // Set to "Validate"

      updatedHeaders[index].simplifiedinvoiceheaderanswerdetails =
        updatedHeaders[index].simplifiedinvoiceheaderanswerdetails.map(
          (item) => ({
            ...item,
            pan_holder_name: "",
          })
        );
    }
    setLocalInvoiceHeaders(updatedHeaders);

    if (formattedValue.trim() !== "") {
      setErrorIndexes((prev) => prev.filter((i) => i !== index));
    }
  };

  const handleActionButtonClick = (index) => {
    const { notificationHandler } = props;
    const updatedHeaders = [...localInvoiceHeaders];

  
    const req = `?input=${updatedHeaders[index].singleInput}&input_type=GST`;
  
    getGstAndPanDetails(req)
      .then((response) => {  
        // Extract GST details from response
        const gstLegalName = response.lgnm || "";
        const gstTradeName = response.tradeNam || "";
        const gstRegistrationStatus = response.dty || "";
  
        // If simplifiedinvoiceheaderanswerdetails is empty, initialize it
        if (!updatedHeaders[index].simplifiedinvoiceheaderanswerdetails.length) {
          updatedHeaders[index].simplifiedinvoiceheaderanswerdetails = [{
            gst_legal_name: gstLegalName,
            gst_trade_name: gstTradeName,
            gst_registration_status: gstRegistrationStatus,
          }];
        } else {
          updatedHeaders[index].simplifiedinvoiceheaderanswerdetails =
            updatedHeaders[index].simplifiedinvoiceheaderanswerdetails.map((item) => ({
              ...item,
              gst_legal_name: gstLegalName || item.gst_legal_name,
              gst_trade_name: gstTradeName || item.gst_trade_name,
              gst_registration_status: gstRegistrationStatus || item.gst_registration_status,
            }));
        }
  
        // Mark as verified if all required fields are filled
        const isVerified = updatedHeaders[index].simplifiedinvoiceheaderanswerdetails.every(
          (item) =>
            item.gst_legal_name &&
            item.gst_trade_name &&
            item.gst_registration_status
        );
        updatedHeaders[index].isVerified = isVerified;  
        setLocalInvoiceHeaders([...updatedHeaders]);
  
        notificationHandler({
          messageText: `GST number validated successfully.`,
          status: "SUCCESS",
        });
      })
      .catch((error) => {
        notificationHandler({
          messageText: `Invalid GST format. The given GST number is not in the proper format.`,
          status: "FAILED",
        });
      });
  };


  const handlePanButtonClick = (index) => {
    const { notificationHandler } = props;
    const updatedHeaders = [...localInvoiceHeaders];
    const req = `?input=${updatedHeaders[index].singleInput}&input_type=PAN`;
  
    getGstAndPanDetails(req)
      .then((response) => {
          // Extract PAN holder name from response
        const panHolderName = response.name || response.name_on_card || "";
        // If simplifiedinvoiceheaderanswerdetails is empty, initialize it
        if (!updatedHeaders[index].simplifiedinvoiceheaderanswerdetails.length) {
          updatedHeaders[index].simplifiedinvoiceheaderanswerdetails = [{
            pan_holder_name: panHolderName,
          }];
        } else {
          updatedHeaders[index].simplifiedinvoiceheaderanswerdetails =
            updatedHeaders[index].simplifiedinvoiceheaderanswerdetails.map((item) => ({
              ...item,
              pan_holder_name: panHolderName || item.pan_holder_name,
            }));
        }
  
        // Mark as verified if all entries have a pan_holder_name
        const isVerified = updatedHeaders[index].simplifiedinvoiceheaderanswerdetails.every(
          (item) => item.pan_holder_name
        );
        updatedHeaders[index].isVerified = isVerified;  
        setLocalInvoiceHeaders([...updatedHeaders]);
        notificationHandler({
          messageText: `PAN number validated successfully.`,
          status: "SUCCESS",
        });
      })
      .catch((error) => {
        notificationHandler({
          messageText: `Invalid PAN number. The given PAN number is not in the proper format or invalid.`,
          status: "FAILED",
        });
      });
  };
  
  


  const handleSaveInvoiceHeader = async () => {
    const { notificationHandler } = props;
 


    const errors = [];
    const errorMsgs = {};


    localInvoiceHeaders.forEach((detail, index) => {    
      const value = detail.singleInput?.trim();
      const invoiceHeaderType = detail.invoiceHeadersTypes;
      const isMandatory = detail.is_mandatory;
    
      const headerValidation = detail.invoiceHeaderValidation || {};    
      const {
        min_length,
        max_length,
        greater_than,
        lower_than,
        lower_limit,
        upper_limit,
        allow_decimals,
        min_images_count,
        max_images_count,
        choices,
      } = headerValidation;


    
      if (isMandatory) {
        if (value === "") {
          errors.push(index);
          errorMsgs[index] = `${detail.header} cannot be empty.`;
          return;
        }
      } else {
        if (value === "") {
          return; // Skip further validation for non-mandatory fields if empty
        }
      }
    
      switch (invoiceHeaderType) {
        case "text":
          if (isMandatory) {
            if (
              (min_length && value.length < parseInt(min_length)) ||
              (max_length && value.length > parseInt(max_length))
            ) {
              errors.push(index);
              errorMsgs[
                index
              ] = `${detail.header} must be between ${min_length} and ${max_length} characters.`;
            }
          } else {
            // If not mandatory, only validate if the value is not empty
            if (value.trim() !== "") {
              if (
                (min_length && value.length < parseInt(min_length)) ||
                (max_length && value.length > parseInt(max_length))
              ) {
                errors.push(index);
                errorMsgs[
                  index
                ] = `${detail.header} must be between ${min_length} and ${max_length} characters.`;
              }
            }
          }
          break;
        

          // case "number":
          //   if (isMandatory) {
          //     if (
          //       (lower_limit && value.length < parseInt(lower_limit)) ||
          //       (upper_limit && value.length > parseInt(upper_limit))
          //     ) {
          //       errors.push(index);
          //       errorMsgs[
          //         index
          //       ] = `${detail.header} must be between ${lower_limit} and ${upper_limit} characters.`;
          //     }
          //   } else {
          //     // If not mandatory, only validate if the value is not empty
          //     if (value.trim() !== "") {
          //       if (
          //         (lower_limit && value.length < parseInt(lower_limit)) ||
          //         (upper_limit && value.length > parseInt(upper_limit))
          //       ) {
          //         errors.push(index);
          //         errorMsgs[
          //           index
          //         ] = `${detail.header} must be between ${lower_limit} and ${upper_limit} characters.`;
          //       }
          
          //       // Validation for decimal allowance
          //       if (!allow_decimals) {
          //         if (
          //           (lower_limit && value.length < parseInt(lower_limit)) ||
          //           (upper_limit && value.length > parseInt(upper_limit))
          //         ) {
          //         if (!/^\d+$/.test(value)) {
          //           errors.push(index);
          //           errorMsgs[
          //             index
          //           ] =  `${detail.header} must be between ${lower_limit} and ${upper_limit} characters.`;
          //         }
          //       }
          //     }
              
          //     }
          //   }
          //   break;

          case "number":
  if (isMandatory) {
    if (value.trim() === "") {
      errors.push(index);
      errorMsgs[index] = `${detail.header} is mandatory.`;
    } else {
      // Check if decimals are allowed
      if (allow_decimals) {
        const numericValue = parseFloat(value);
        const lowerLimit = lower_limit ? parseFloat(lower_limit) : null;
        const upperLimit = upper_limit ? parseFloat(upper_limit) : null;

        if (
          (lowerLimit !== null && numericValue < lowerLimit) ||
          (upperLimit !== null && numericValue > upperLimit)
        ) {
          errors.push(index);
          errorMsgs[index] = `${detail.header} must be between ${lower_limit} and ${upper_limit}.`;
        }
      } else {
        if (!/^\d+$/.test(value)) {
          errors.push(index);
          errorMsgs[index] = `${detail.header} must be a whole number.`;
        } else {
          const numericValue = parseInt(value);
          const lowerLimit = lower_limit ? parseInt(lower_limit) : null;
          const upperLimit = upper_limit ? parseInt(upper_limit) : null;

          if (
            (lowerLimit !== null && numericValue < lowerLimit) ||
            (upperLimit !== null && numericValue > upperLimit)
          ) {
            errors.push(index);
            errorMsgs[index] = `${detail.header} must be between ${lower_limit} and ${upper_limit}.`;
          }
        }
      }
    }
  } else {
    // If not mandatory, only validate if value is not empty
    if (value.trim() !== "") {
      if (allow_decimals) {
        const numericValue = parseFloat(value);
        const lowerLimit = lower_limit ? parseFloat(lower_limit) : null;
        const upperLimit = upper_limit ? parseFloat(upper_limit) : null;

        if (
          (lowerLimit !== null && numericValue < lowerLimit) ||
          (upperLimit !== null && numericValue > upperLimit)
        ) {
          errors.push(index);
          errorMsgs[index] = `${detail.header} must be between ${lower_limit} and ${upper_limit}.`;
        }
      } else {
        if (!/^\d+$/.test(value)) {
          errors.push(index);
          errorMsgs[index] = `${detail.header} must be a whole number.`;
        } else {
          const numericValue = parseInt(value);
          const lowerLimit = lower_limit ? parseInt(lower_limit) : null;
          const upperLimit = upper_limit ? parseInt(upper_limit) : null;

          if (
            (lowerLimit !== null && numericValue < lowerLimit) ||
            (upperLimit !== null && numericValue > upperLimit)
          ) {
            errors.push(index);
            errorMsgs[index] = `${detail.header} must be between ${lower_limit} and ${upper_limit}.`;
          }
        }
      }
    }
  }
  break;

          
        case "date":
          const inputDate = new Date(value);
          const inputDateStr = inputDate.toISOString().split("T")[0];

          const lowerLimitDate = lower_than ? new Date(lower_than) : null;
          const upperLimitDate = greater_than ? new Date(greater_than) : null;

          let adjustedLowerLimitDateStr = lowerLimitDate
            ? lowerLimitDate.toISOString().split("T")[0]
            : null;
          let adjustedUpperLimitDateStr = upperLimitDate
            ? upperLimitDate.toISOString().split("T")[0]
            : null;

          if (adjustedLowerLimitDateStr && adjustedUpperLimitDateStr) {
            if (
              new Date(adjustedLowerLimitDateStr) >
              new Date(adjustedUpperLimitDateStr)
            ) {
              const temp = adjustedLowerLimitDateStr;
              adjustedLowerLimitDateStr = adjustedUpperLimitDateStr;
              adjustedUpperLimitDateStr = temp;
            }
          }

          if (
            (adjustedLowerLimitDateStr &&
              inputDateStr < adjustedLowerLimitDateStr) ||
            (adjustedUpperLimitDateStr &&
              inputDateStr > adjustedUpperLimitDateStr)
          ) {
            if (isMandatory) {
              errors.push(index);
              errorMsgs[
                index
              ] = `${detail.header} must be between ${greater_than} and ${lower_than}.`;
            }
          }
          break;

        case "time":
          if (value) {
            let checkTime = moment(value, "HH:mm:ss");
            let stateTimeN = moment(greater_than, "HH:mm:ss");
            let endTimeN = moment(lower_than, "HH:mm:ss");

            if (
              !(checkTime.isAfter(stateTimeN) && checkTime.isBefore(endTimeN))
            ) {
              if (isMandatory) {
                errors.push(index);
                errorMsgs[
                  index
                ] = `${detail.header} must be between ${greater_than} and ${lower_than}.`;
              }
            }
          }
          break;

        case "multiple_image":
          const imageCount = value ? value.split(",").length : 0;
          if (
            (min_images_count && imageCount < parseInt(min_images_count)) ||
            (max_images_count && imageCount > parseInt(max_images_count))
          ) {
            if (isMandatory) {
              errors.push(index);
              errorMsgs[
                index
              ] = `${detail.header} must have between ${min_images_count} and ${max_images_count} images.`;
            }
          }
          break;

        case "dropdown":
          if (!choices.includes(value)) {
            if (isMandatory) {
              errors.push(index);
              errorMsgs[
                index
              ] = `Invalid selection for ${detail.header}. Please select a valid option.`;
            }
          }
          break;

        case "gst_number":
          const gstRegex =
            /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}[Z]{1}[0-9A-Z]{1}$/;

          if (!gstRegex.test(value)) {
            if (isMandatory) {
              errors.push(index);
              errorMsgs[index] = `Invalid GST Number.`;
            }
          }
          break;

        case "pan_number":
          const panRegex = /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/;

          if (!panRegex.test(value)) {
            if (isMandatory) {
              errors.push(index);
              errorMsgs[index] = `Invalid PAN Number.`;
            }
          }
          break;

        default:
          console.warn(`Unknown field type: ${invoiceHeaderType}`);
          break;
      }
    });
    

    if (errors.length > 0) {
      setErrorIndexes(errors);
      setErrorMessages(errorMsgs);
      return;
    }

    setErrorIndexes([]);
    setErrorMessages({});
 

    const currentCardData = cardDataForSKus?.results[selectedCard];
    if (!currentCardData) return;

    const updatedSimplifiedInvoiceHeaders = [...(currentCardData.simplifiedinvoiceheaderanswer || [])];
    let hasChanges = false; // Track if any changes were made



    localInvoiceHeaders.forEach((localHeader) => {
      updatedSimplifiedInvoiceHeaders.forEach((header) => {
          if (header.invoice_header_type === "gst_number") {
              const gstHeader = localInvoiceHeaders.find((lh) => lh.type === "gst_number");
              const gstHeaderFromUpdateSimplifiedInvoice = updatedSimplifiedInvoiceHeaders.find(
                  (h) => h.invoice_header_type === "gst_number"
              );
              
              if (gstHeader && gstHeaderFromUpdateSimplifiedInvoice) {
                  const gstNumberDetails = gstHeader?.simplifiedinvoiceheaderanswerdetails?.[0];
                  const gstHeaderDetailsToUpdate = gstHeaderFromUpdateSimplifiedInvoice?.simplifiedinvoiceheaderanswerdetails;

                  if (gstNumberDetails && gstHeaderDetailsToUpdate) {
                      const { gst_legal_name, gst_registration_status, gst_trade_name } = gstNumberDetails;

                      if (gstHeaderDetailsToUpdate.length === 0) {
                          gstHeaderDetailsToUpdate.push({ gst_legal_name, gst_registration_status, gst_trade_name, is_edit: true });
                          hasChanges = true;
                          header.is_edit = true;
                      } 
                      else {
                          Object.assign(gstHeaderDetailsToUpdate[0], { gst_legal_name, gst_registration_status, gst_trade_name ,is_edit: true});
                          hasChanges = true;
                          header.is_edit = true;
                      }

                  }
              }
          }

          if (header.invoice_header_type === "pan_number") {
            const gstHeader = localInvoiceHeaders.find((lh) => lh.type === "pan_number");
            const gstHeaderFromUpdateSimplifiedInvoice = updatedSimplifiedInvoiceHeaders.find(
                (h) => h.invoice_header_type === "pan_number"
            );

            if (gstHeader && gstHeaderFromUpdateSimplifiedInvoice) {
                const gstNumberDetails = gstHeader?.simplifiedinvoiceheaderanswerdetails?.[0];
                const gstHeaderDetailsToUpdate = gstHeaderFromUpdateSimplifiedInvoice?.simplifiedinvoiceheaderanswerdetails;

                if (gstNumberDetails && gstHeaderDetailsToUpdate) {
                    const { pan_holder_name } = gstNumberDetails;

                    if (gstHeaderDetailsToUpdate.length === 0) {
                        gstHeaderDetailsToUpdate.push({ pan_holder_name, is_edit: true });
                        hasChanges = true;
                        header.is_edit = true;
                    } else {
                        Object.assign(gstHeaderDetailsToUpdate[0], { pan_holder_name,is_edit:true });
                        hasChanges = true;
                        header.is_edit = true;

                    }

                }
            }
        }
          // Check and update single_input value if needed
          if (header.invoice_header_name === localHeader.header) {
              if (header.single_input?.trim() !== localHeader.singleInput?.trim()) {
                  header.single_input = localHeader.singleInput;
                  header.is_edit = true; // Mark the header as edited
                  hasChanges = true; // Flag that there are changes
              }
          }
      });
  });


    const payload = {
      ...currentCardData,
      is_edit: hasChanges,
      is_edit_simplifiedinvoiceheaderanswer: hasChanges,
      simplifiedinvoiceheaderanswer: updatedSimplifiedInvoiceHeaders,
    };
    try {
      await createSkuCollectedBills(currentCardData.id, payload);

      notificationHandler({
        messageText: `Invoice Header saved  Successfully.`,
        status: "SUCCESS",
      });
      setRefreshData((prev) => !prev);
      
    } catch (error) {
      console.error("Error sending payload:", error);
    } finally {
      setEditLoader(false); // Hide loader
    }
  };

  const handleDeleteRow = () => {
    if (isNewSKUDelete) {
      const updatedTableData = [...tableData];
      updatedTableData.splice(selectedRowIndex, 1);
      setTableData(updatedTableData);
      // setRefreshData((prev) => !prev);
      setIsNewSKUDelete(false)
    }else{
    
    triggerSimpleAjax(
      `${DELETE_COLLECTED_BILLS_SKU}${selectedSkuID}`,
      "DELETE",
      {},
      () => {
        const updatedTableData = [...tableData];
        updatedTableData.splice(selectedRowIndex, 1);
        setTableData(updatedTableData);
        setRefreshData((prev) => !prev);
      },
      (error) => {
        console.log(error);
      }
    );
  }
  };

  const simplifiedInvoiceSkuData =
    cardDataForSKus?.results[selectedCard]?.simplifiedinvoiceskudata || [];

  const [selectedSkuIds, setSelectedSkuIds] = useState([]);

  const handleCheckboxChange = (id, isChecked) => {
    setSelectedSkuIds((prevSelectedIds) =>
      isChecked
        ? [...prevSelectedIds, id]
        : prevSelectedIds.filter((skuId) => skuId !== id)
    );
  };

  const handleExportToExcelButtonClick = (id) => {
    const payload = {
      data_to_export: [
        {
          id: selectedSkuIds,
        },
      ],
      workflow_object_id: props.outletDetails.workflow_object,
      add_on_sku: "Test",
    };

    createaddskuexcel(id, payload)
      .then((response) => {
        const filePath = response.file_path.replace(
          "/app/retailscan_backend/",
          `${AppConfig.api_url}`
        );
        window.open(filePath, "_blank");
      })
      .catch((error) => {
        console.error("API Error:", error);
      });
  };

  return (
    <React.Fragment>
      {loading ? (
        <div style={{ textAlign: "center", padding: "50px" }}>
          <CircularProgress size={80} style={{ color: "#318CE7" }} />{" "}
        </div>
      ) : (
        <div
          style={{
            marginBottom: "10px",
            position: "sticky",
            padding: "10px 0",
            overflow: "hidden", // Prevents extra scrolling
            height: "auto", // Set a fixed height to avoid extra scrolling
          }}
        >
          <Box>
            <Box display="flex" alignItems="center" mb={2}>
              <IconButton
                onClick={() => scroll(-300)}
                style={{ backgroundColor: "#1976D2", color: "white" }}
              >
                <ArrowBack />
              </IconButton>
              <Box
                ref={cardContainerRef}
                style={{
                  display: "flex",
                  overflowX: "auto",
                  whiteSpace: "nowrap",
                  scrollBehavior: "smooth",
                  width: "91%",
                  padding: "10px",
                  margin: "0 2px",
                  borderRadius: "8px",
                  backgroundColor: "#ffffff",
                  boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
                }}
              >
                <>
                  {cardDataForSKus?.results.map((card, index) => (
                    <button
                      key={index}
                      onClick={() => {
                        setSelectedCard(index);
                        setErrorIndexes([]);
                        setErrorMessages({});
                        setTimeout(() => {
                          fetchData();
                        }, 100);
                      }}
                      style={{
                        border:
                          savedCard !== null && index <= savedCard
                            ? "2px solid green"
                            : selectedCard === index
                            ? "2px solid #1976D2"
                            : `2px solid ${black200}`,
                        backgroundColor: "transparent",
                        margin: "4px 13px 0 0px",
                        height: "40px",
                        padding: "7px",
                        borderRadius: "5px",
                        display: "inline-block",
                        textAlign: "left",
                        cursor: "pointer",
                      }}
                    >
                      <div
                        className="button-content"
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          width: "100%",
                        }}
                      >
                        <div style={{ display: "flex", alignItems: "center" }}>
                          {savedCard !== null && index <= savedCard ? (
                            <CheckCircleIcon
                              style={{ color: "green", marginRight: "8px" }}
                            />
                          ) : selectedCard === index ? (
                            <RadioButtonCheckedIcon
                              style={{ color: "#1976D2", marginRight: "8px" }}
                            />
                          ) : (
                            <RadioButtonUncheckedIcon
                              style={{ color: black200, marginRight: "8px" }}
                            />
                          )}

                          <span
                            style={{
                              color: "black",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              whiteSpace: "nowrap",
                              display: "inline-block",
                              maxWidth: "100%",
                            }}
                            title={cardTexts[index]}
                          >
                            {cardTexts[index]}
                          </span>

                          {enableEditAnswers && selectedCard === index && (
                            <span
                              style={{
                                margin: "0 8px",
                                height: "16px",
                                borderRight: "1px solid gray",
                              }}
                            />
                          )}
                        </div>

                        {enableEditAnswers && selectedCard === index && (
                          <EditIcon
                            style={{ color: "gray", cursor: "pointer" }}
                            onClick={(e) => {
                              e.stopPropagation(); // Prevent button click event
                              handleEditClick(index); // Open dialog when Edit icon is clicked
                            }}
                          />
                        )}
                      </div>
                    </button>
                  ))}

                  {/* Empty Popup Dialog */}
                  <Dialog open={open} onClose={handleClose}>
                    <DialogTitle>Edit Invoice Header</DialogTitle>
                    <DialogContent>
                      <TextField
                        label="Invoice Number"
                        variant="outlined"
                        fullWidth
                        value={invoiceNo}
                        onChange={handleChange}
                        onBlur={handleBlur} // Validate when the user leaves the field
                        error={error} // MUI error state
                        helperText={error ? "Invoice Number is required" : ""} // Error message
                        style={{ marginBottom: "16px" }}
                      />
                      <TextField
                        label="Invoice Date"
                        variant="outlined"
                        fullWidth
                        value={invoiceDate}
                        onChange={(e) => setInvoiceDate(e.target.value)}
                        type="date"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        inputProps={{
                          max: today, // Restrict future dates
                        }}
                      />
                    </DialogContent>
                    <DialogActions>
                      <Button
                        onClick={handleClose}
                        color={black100}
                        bgColor={black500}
                      >
                        Close
                      </Button>
                      <Button
                        onClick={handleSave}
                        color="primary"
                        bgColor={blue300}
                        isDisabled={!invoiceNo}
                      >
                        Save
                      </Button>
                    </DialogActions>
                  </Dialog>
                </>

                <button
                  key="new-sku-data"
                  onClick={() => handleCardClick("new-sku")}
                  style={{
                    border: "1px solid #ccc",
                    margin: "4px 13px 0 0px", // Reduced margin
                    height: "40px",
                    borderRadius: "5px",
                  }}
                >
                  <div className="button-content">
                    <span className="button-text">New SKU Data</span>
                  </div>
                </button>
              </Box>

              <IconButton
                onClick={() => scroll(300)}
                style={{ backgroundColor: "#1976D2", color: "white" }}
              >
                <ArrowForward />
              </IconButton>
            </Box>
          </Box>

          {selectedCard !== null && selectedCard !== "new-sku" && (
            <div className="table-container">
              <div
                className="empty-card"
                style={{
                  border: "1px solid #ccc",
                  padding: "10px",
                  borderRadius: "8px",
                }}
              >
                <div
                  className="card-header"
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginBottom: "10px",
                  }}
                >
                  <p
                    style={{
                      fontFamily: "barlow",
                      fontSize: "16px",
                      fontWeight: "700",
                      lineHeight: "20px",
                      textAlign: "left",
                      margin: 0, // Remove default margin for a cleaner look
                    }}
                  >
                    Invoice Details
                  </p>
                  {isExpanded ? (
                    <ExpandLessIcon
                      onClick={toggleExpandCollapse}
                      className="toggle-icon"
                      style={{ cursor: "pointer" }}
                    />
                  ) : (
                    <ExpandMoreIcon
                      onClick={toggleExpandCollapse}
                      className="toggle-icon"
                      style={{ cursor: "pointer" }}
                    />
                  )}
                </div>

                {isExpanded && (
                  <div
                    className="card-content"
                    style={{
                      padding: "10px 0",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                      height: "100%",
                    }}
                  >
                    {localInvoiceHeaders && localInvoiceHeaders.length > 0 ? (
                      <div
                        style={{
                          marginTop: "20px",
                          display: "grid",
                          gap: "8px",
                          gridTemplateColumns:
                            "repeat(auto-fit, minmax(300px, 1fr))",
                          justifyContent: "end",
                        }}
                      >
                        {localInvoiceHeaders.map((detail, index) => (
                          <div
                            key={index}
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              padding: "12px",
                              backgroundColor: "#fafafa",
                              borderRadius: "6px",
                              boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
                              minWidth: "250px",
                              gridColumn:
                                detail.invoiceHeadersTypes === "pan_number"
                                  ? "span 2"
                                  : detail.invoiceHeadersTypes === "gst_number"
                                  ? "span 3"
                                  : "span 1",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "start",
                                alignItems: "center",
                                width: "100%",
                              }}
                            >
                              <span
                                style={{
                                  fontFamily: "barlow",
                                  fontSize: "14px",
                                  fontWeight: "600",
                                  color: "#333",
                                  whiteSpace: "nowrap",
                                  marginBottom: "6px",
                                }}
                              >
                                {detail.header}:
                              </span>

                              {detail.invoiceHeadersTypes ===
                                "multiple_image" && (
                                <input
                                  type="text"
                                  value={detail.singleInput}
                                  placeholder={`Enter ${detail.header}`}
                                  readOnly
                                  onChange={(e) =>
                                    handleSingleInputChange(e, index)
                                  }
                                  style={{
                                    fontFamily: "barlow",
                                    fontSize: "14px",
                                    color: "#666",
                                    border: errorIndexes.includes(index)
                                      ? "1px solid red"
                                      : "1px solid #ccc",
                                    borderRadius: "4px",
                                    padding: "6px",
                                    flexGrow: 1,
                                    outline: "none",
                                    marginLeft: "10px",
                                    height: "40px",
                                    width: "calc(100% - 20px)",
                                    maxWidth: "80%",
                                  }}
                                />
                              )}
                              {detail.invoiceHeadersTypes === "dropdown" && (
                                <select
                                  value={
                                    detail.singleInput ||
                                    (detail.invoiceHeaderValidation.choices
                                      ? detail.invoiceHeaderValidation.choices
                                          .split(";")[0]
                                          .trim()
                                      : "")
                                  }
                                  onChange={(e) =>
                                    handleSingleInputChange(e, index)
                                  }
                                  style={{
                                    fontFamily: "barlow",
                                    fontSize: "14px",
                                    color: "#666",
                                    border: errorIndexes.includes(index)
                                      ? "1px solid red"
                                      : "1px solid #ccc",
                                    borderRadius: "4px",
                                    padding: "6px",
                                    flexGrow: 1,
                                    outline: "none",
                                    marginLeft: "10px",
                                    height: "40px",
                                    width: "calc(100% - 20px)",
                                    maxWidth: "80%",
                                  }}
                                >
                                  {detail.invoiceHeaderValidation.choices &&
                                    detail.invoiceHeaderValidation.choices
                                      .split(";")
                                      .map((choice, idx) => (
                                        <option
                                          style={{
                                            lineHeight: "1.2",
                                          }}
                                          key={idx}
                                          value={choice.trim()}
                                        >
                                          {choice.trim()}
                                        </option>
                                      ))}
                                </select>
                              )}

                              {detail.invoiceHeadersTypes === "gst_number" && (
                                <>
                                  <input
                                    type="text"
                                    value={detail.singleInput}
                                    placeholder={`Enter ${detail.header}`}
                                    title={detail.singleInput}
                                    onChange={(e) =>
                                      handleSingleInputChange(e, index)
                                    }
                                    style={{
                                      fontFamily: "barlow",
                                      fontSize: "14px",
                                      color: "#666",
                                      border: errorIndexes.includes(index)
                                        ? "1px solid red"
                                        : "1px solid #ccc",
                                      borderRadius: "4px",
                                      padding: "6px",
                                      flexGrow: 1,
                                      outline: "none",
                                      marginLeft: "10px",
                                      height: "40px",
                                      width: "calc(100% - 20px)",
                                      maxWidth: "80%",
                                    }}
                                  />
                                  <>
                                    <span
                                      style={{
                                        fontFamily: "barlow",
                                        fontSize: "14px",
                                        fontWeight: "600",
                                        color: "#333",
                                        whiteSpace: "nowrap",
                                        marginLeft: "8px",
                                      }}
                                    >
                                      GST Legal Name:
                                    </span>
                                    <input
                                      type="text"
                                      title={
                                        detail
                                          ?.simplifiedinvoiceheaderanswerdetails?.[0]
                                          ?.gst_legal_name
                                      }
                                      value={
                                        detail
                                          ?.simplifiedinvoiceheaderanswerdetails?.[0]
                                          ?.gst_legal_name || ""
                                      }
                                      style={{
                                        fontFamily: "barlow",
                                        fontSize: "14px",
                                        color: "#666",
                                        border: errorIndexes.includes(index)
                                          ? "1px solid red"
                                          : "1px solid #ccc",
                                        borderRadius: "4px",
                                        padding: "6px",
                                        flexGrow: 1,
                                        outline: "none",
                                        marginLeft: "10px",
                                        height: "40px",
                                        width: "calc(100% - 20px)",
                                        maxWidth: "80%",
                                      }}
                                    />
                                    <span
                                      style={{
                                        fontFamily: "barlow",
                                        fontSize: "14px",
                                        fontWeight: "600",
                                        color: "#333",
                                        whiteSpace: "nowrap",
                                        marginLeft: "8px",
                                      }}
                                    >
                                      GST Trade Name:
                                    </span>
                                    <input
                                      type="text"
                                      title={
                                        detail
                                          ?.simplifiedinvoiceheaderanswerdetails?.[0]
                                          ?.gst_trade_name
                                      }
                                      value={
                                        detail
                                          ?.simplifiedinvoiceheaderanswerdetails?.[0]
                                          ?.gst_trade_name || ""
                                      }
                                      style={{
                                        fontFamily: "barlow",
                                        fontSize: "14px",
                                        color: "#666",
                                        border: errorIndexes.includes(index)
                                          ? "1px solid red"
                                          : "1px solid #ccc",
                                        borderRadius: "4px",
                                        padding: "6px",
                                        flexGrow: 1,
                                        outline: "none",
                                        marginLeft: "10px",
                                        height: "40px",
                                        width: "calc(100% - 20px)",
                                        maxWidth: "80%",
                                      }}
                                    />
                                    <span
                                      style={{
                                        fontFamily: "barlow",
                                        fontSize: "14px",
                                        fontWeight: "600",
                                        color: "#333",
                                        whiteSpace: "nowrap",
                                        marginLeft: "8px",
                                      }}
                                    >
                                      GST Registration Status:
                                    </span>
                                    <input
                                      type="text"
                                      title={
                                        detail
                                          ?.simplifiedinvoiceheaderanswerdetails?.[0]
                                          ?.gst_registration_status
                                      }
                                      value={
                                        detail
                                          ?.simplifiedinvoiceheaderanswerdetails?.[0]
                                          ?.gst_registration_status || ""
                                      }
                                      style={{
                                        fontFamily: "barlow",
                                        fontSize: "14px",
                                        color: "#666",
                                        border: errorIndexes.includes(index)
                                          ? "1px solid red"
                                          : "1px solid #ccc",
                                        borderRadius: "4px",
                                        padding: "6px",
                                        flexGrow: 1,
                                        outline: "none",
                                        marginLeft: "10px",
                                        height: "40px",
                                        width: "calc(100% - 20px)",
                                        maxWidth: "80%",
                                      }}
                                    />

                                    <div
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        marginLeft: "8px",
                                        backgroundColor:
                                          detail
                                            ?.simplifiedinvoiceheaderanswerdetails?.[0]
                                            ?.gst_legal_name &&
                                          detail
                                            ?.simplifiedinvoiceheaderanswerdetails?.[0]
                                            ?.gst_registration_status &&
                                          detail
                                            ?.simplifiedinvoiceheaderanswerdetails?.[0]
                                            ?.gst_trade_name
                                            ? "rgb(76, 175, 80)"
                                            : "rgb(76, 175, 80)", // Adjust fallback color as needed
                                        color: "#fff",
                                        borderRadius: "2px",
                                        padding: "11px 10px",
                                        fontSize: "13px",
                                        cursor:
                                          detail
                                            ?.simplifiedinvoiceheaderanswerdetails?.[0]
                                            ?.gst_legal_name &&
                                          detail
                                            ?.simplifiedinvoiceheaderanswerdetails?.[0]
                                            ?.gst_registration_status &&
                                          detail
                                            ?.simplifiedinvoiceheaderanswerdetails?.[0]
                                            ?.gst_trade_name
                                            ? "not-allowed"
                                            : "pointer",
                                      }}
                                      // onClick={() => {
                                      //   if (detail
                                      //     ?.simplifiedinvoiceheaderanswerdetails?.[0]
                                      //     ?.gst_legal_name !==""&&
                                      //   detail
                                      //     ?.simplifiedinvoiceheaderanswerdetails?.[0]
                                      //     ?.gst_registration_status!=="" &&
                                      //   detail
                                      //     ?.simplifiedinvoiceheaderanswerdetails?.[0]
                                      //     ?.gst_trade_name!=="") {
                                      //     handleActionButtonClick(index);
                                      //   }
                                      // }}

                                      onClick={() => {
                                        if (
                                          !(
                                            detail
                                              ?.simplifiedinvoiceheaderanswerdetails?.[0]
                                              ?.gst_legal_name !== "" ||
                                            detail
                                              ?.simplifiedinvoiceheaderanswerdetails?.[0]
                                              ?.gst_registration_status !==
                                              "" ||
                                            detail
                                              ?.simplifiedinvoiceheaderanswerdetails?.[0]
                                              ?.gst_trade_name !== ""
                                          )
                                        ) {
                                          handleActionButtonClick(index);
                                        }
                                      }}
                                    >
                                      {detail
                                        ?.simplifiedinvoiceheaderanswerdetails?.[0]
                                        ?.gst_legal_name ||
                                      detail
                                        ?.simplifiedinvoiceheaderanswerdetails?.[0]
                                        ?.gst_registration_status ||
                                      detail
                                        ?.simplifiedinvoiceheaderanswerdetails?.[0]
                                        ?.gst_trade_name ? (
                                        <>
                                          <span style={{ marginRight: "8px" }}>
                                            Verified
                                          </span>
                                          <CheckCircleIcon
                                            style={{ fontSize: "16px" }}
                                          />
                                        </>
                                      ) : (
                                        <>
                                          <span>Validate</span>
                                        </>
                                      )}
                                    </div>
                                  </>
                                </>
                              )}

                              {detail.invoiceHeadersTypes === "pan_number" && (
                                <>
                                  <input
                                    type="text"
                                    value={detail.singleInput}
                                    title={detail.singleInput}
                                    placeholder={`Enter ${detail.header}`}
                                    onChange={(e) =>
                                      handleSingleInputChange(e, index)
                                    }
                                    style={{
                                      fontFamily: "barlow",
                                      fontSize: "14px",
                                      color: "#666",
                                      border: errorIndexes.includes(index)
                                        ? "1px solid red"
                                        : "1px solid #ccc",
                                      borderRadius: "4px",
                                      padding: "6px",
                                      flexGrow: 1,
                                      outline: "none",
                                      marginLeft: "10px",
                                      height: "40px",
                                      maxWidth: "80%",
                                    }}
                                  />
                                  <>
                                   
                                    <span
                                      style={{
                                        fontFamily: "barlow",
                                        fontSize: "14px",
                                        fontWeight: "600",
                                        color: "#333",
                                        whiteSpace: "nowrap",
                                        marginLeft: "8px",
                                      }}
                                    >
                                      PAN Holder Name:-
                                    </span>
                                    <input
                                      type="text"
                                      title={
                                        detail
                                          ?.simplifiedinvoiceheaderanswerdetails?.[0]
                                          ?.pan_holder_name
                                      }
                                      value={
                                        detail
                                          ?.simplifiedinvoiceheaderanswerdetails?.[0]
                                          ?.pan_holder_name || ""
                                      }
                                      style={{
                                        fontFamily: "barlow",
                                        fontSize: "14px",
                                        color: "#666",
                                        border: errorIndexes.includes(index)
                                          ? "1px solid red"
                                          : "1px solid #ccc",
                                        borderRadius: "4px",
                                        padding: "6px",
                                        flexGrow: 1,
                                        outline: "none",
                                        marginLeft: "10px",
                                        height: "40px",
                                        width: "calc(100% - 20px)",
                                        maxWidth: "80%",
                                      }}
                                    />

                                    <div
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        marginLeft: "8px",
                                        backgroundColor: detail
                                          ?.simplifiedinvoiceheaderanswerdetails?.[0]
                                          ?.pan_holder_name
                                          ? "rgb(76, 175, 80)"
                                          : "rgb(76, 175, 80)",
                                        color: "#fff",
                                        borderRadius: "2px",
                                        padding: "11px 10px",
                                        fontSize: "13px",
                                        cursor: detail
                                          ?.simplifiedinvoiceheaderanswerdetails?.[0]
                                          ?.pan_holder_name
                                          ? "not-allowed"
                                          : "pointer",
                                      }}
                                      onClick={() => {
                                        if (
                                          !detail
                                            .simplifiedinvoiceheaderanswerdetails[0]
                                            ?.pan_holder_name ||
                                          detail.simplifiedinvoiceheaderanswerdetails[0]?.pan_holder_name.trim() ===
                                            ""
                                        ) {
                                          handlePanButtonClick(index);
                                        }
                                      }}
                                    >
                                      {detail
                                        ?.simplifiedinvoiceheaderanswerdetails?.[0]
                                        ?.pan_holder_name ? (
                                        <>
                                          <span style={{ marginRight: "8px" }}>
                                            Verified
                                          </span>
                                          <CheckCircleIcon
                                            style={{ fontSize: "16px" }}
                                          />
                                        </>
                                      ) : (
                                        <>
                                          <span>Validate</span>
                                        </>
                                      )}
                                    </div>
                                  </>
                                </>
                              )}

                              {detail.invoiceHeadersTypes === "text" && (
                                <input
                                  type="text"
                                  value={detail.singleInput}
                                  placeholder={`Enter ${detail.header}`}
                                  onChange={(e) =>
                                    handleSingleInputChange(e, index)
                                  }
                                  style={{
                                    fontFamily: "barlow",
                                    fontSize: "14px",
                                    color: "#666",
                                    border: errorIndexes.includes(index)
                                      ? "1px solid red"
                                      : "1px solid #ccc",
                                    borderRadius: "4px",
                                    padding: "6px",
                                    flexGrow: 1,
                                    outline: "none",
                                    marginLeft: "10px",
                                    height: "40px",
                                    width: "calc(100% - 20px)",
                                    maxWidth: "80%",
                                  }}
                                />
                              )}
                              {detail.invoiceHeadersTypes === "number" && (
                                <input
                                inputMode="decimal" // Prevents number dropdown
                                  value={detail.singleInput}
                                  placeholder={`Enter ${detail.header}`}
                                  onChange={(e) => {
                                    const value = e.target.value;
                                    // Allow only numeric values (including decimal points)
                                    if (
                                      /^\d*\.?\d*$/.test(value) ||
                                      value === ""
                                    ) {
                                      handleSingleInputChange(e, index);
                                    }
                                  }}
                                  style={{
                                    fontFamily: "barlow",
                                    fontSize: "14px",
                                    color: "#666",
                                    border: errorIndexes.includes(index)
                                      ? "1px solid red"
                                      : "1px solid #ccc",
                                    borderRadius: "4px",
                                    padding: "6px",
                                    flexGrow: 1,
                                    outline: "none",
                                    marginLeft: "10px",
                                    height: "40px",
                                    width: "calc(100% - 20px)",
                                    maxWidth: "80%",
                                  }}
                                />
                              )}

                              {detail.invoiceHeadersTypes === "date" && (
                                <input
                                  type="date"
                                  value={
                                    detail.singleInput
                                      ? detail.singleInput.replace(/\//g, "-")
                                      : ""
                                  }
                                  onChange={(e) =>
                                    handleSingleInputChange(e, index)
                                  }
                                  style={{
                                    fontFamily: "barlow",
                                    fontSize: "14px",
                                    color: "#666",
                                    border: errorIndexes.includes(index)
                                      ? "1px solid red"
                                      : "1px solid #ccc",
                                    borderRadius: "4px",
                                    padding: "6px",
                                    flexGrow: 1,
                                    outline: "none",
                                    marginLeft: "10px",
                                    height: "40px",
                                    width: "calc(100% - 20px)",
                                    maxWidth: "80%",
                                  }}
                                />
                              )}

                              {detail.invoiceHeadersTypes === "time" && (
                                <input
                                  type="time"
                                  value={detail.singleInput}
                                  onChange={(e) =>
                                    handleSingleInputChange(e, index)
                                  }
                                  step="1"
                                  style={{
                                    fontFamily: "barlow",
                                    fontSize: "14px",
                                    color: "#666",
                                    border: errorIndexes.includes(index)
                                      ? "1px solid red"
                                      : "1px solid #ccc",
                                    borderRadius: "4px",
                                    padding: "6px",
                                    flexGrow: 1,
                                    outline: "none",
                                    marginLeft: "10px",
                                    height: "40px",
                                    width: "calc(100% - 20px)",
                                    maxWidth: "80%",
                                  }}
                                />
                              )}
                            </div>

                            <div style={{ minHeight: "0px" }}>
                              <span
                                style={{
                                  color: "red",
                                  fontFamily: "barlow",
                                  fontSize: "12px",

                                  marginTop: "5px",
                                  wordWrap: "break-word",
                                  display: errorIndexes.includes(index)
                                    ? "block"
                                    : "none",
                                }}
                              >
                                {errorMessages[index] ||
                                  `Please enter ${detail.header}`}
                              </span>
                            </div>
                          </div>
                        ))}
                        <div
                          style={{
                            gridColumn: "1 / -1", // Ensures it spans the full width
                            display: "flex",
                            justifyContent: "flex-end", // Aligns it to the right
                          }}
                        >
                          {enableEditAnswers ? (
                            <button
                              style={{
                                backgroundColor: "#4CAF50",
                                color: "white",
                                padding: "10px 20px",
                                fontSize: "14px", // Font size
                                fontWeight: "600", // Bold text
                                border: "none", // Remove border
                                borderRadius: "5px", // Rounded corners
                                cursor: "pointer", // Pointer on hover
                                transition: "background-color 0.3s", // Smooth hover effect
                              }}
                              onClick={() => handleSaveInvoiceHeader()} // Replace with your save logic
                              onMouseOver={(e) =>
                                (e.target.style.backgroundColor = "#45a049")
                              } // Hover effect
                              onMouseOut={(e) =>
                                (e.target.style.backgroundColor = green)
                              } // Reset hover effect
                            >
                              Save
                            </button>
                          ) : null}
                        </div>
                      </div>
                    ) : (
                      <p style={{ textAlign: "center", color: "#999" }}>
                        No invoice details found.
                      </p>
                    )}
                  </div>
                )}
              </div>

              <div>
                {tableData && tableData.length >= 0 ? (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    {/* New Button */}

                    {enableEditAnswers ? (
                      <button
                        className="add-empty-row-button"
                        onClick={addEmptyRow}
                      >
                        <Add style={{ color: "#ffffff", marginRight: "8px" }} />
                        Add New Sku
                      </button>
                    ) : null}

                    <button
                      className="total-image"
                      onClick={() => {
                        // Assuming `props.workflow_object_id` contains the value you want to pass
                        const url = `${DASHBOARDROUTES.DASHBOARD_IMAGE_VIEWER.replace(
                          ":workflow_object_id",
                          props.outletDetails.workflow_object
                        )}`;
                        window.open(url, "_blank");
                      }}
                    >
                      <ImageIcon
                        style={{ color: "#ffffff", marginRight: "8px" }}
                      />
                      Total Images
                    </button>
                  </div>
                ) : null}
                <div>
                  {tableData.length === 0 ? (
                    <div className="no-data-container">
                      <span>No sku entries received in bill</span>
                    </div>
                  ) : (
                    <div className="empty-card" style={{ maxWidth: "100%" }}>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "flex-start",
                          marginBottom: "10px",
                          position: "sticky",
                          top: 0,
                          zIndex: 1000,
                          padding: "10px 0",
                        }}
                      ></div>
                      <div
                        style={{
                          overflowX: "auto",
                          maxHeight: "500px",
                          width: "100%",
                          marginTop: "-5%",
                        }}
                      >
                        <table
                          {...getTableProps()}
                          className="no-border"
                          style={{ width: "100%" }}
                        >
                          <thead>
                            {headerGroups.map(
                              (headerGroup, headerGroupIndex) => (
                                <tr
                                  {...headerGroup.getHeaderGroupProps()}
                                  key={`headerGroup-${headerGroupIndex}`}
                                >
                                  {headerGroup.headers.map(
                                    (column, columnIndex) => (
                                      <th
                                        {...column.getHeaderProps()}
                                        key={`column-${columnIndex}`}
                                        style={{
                                          padding: "10px",
                                          wordWrap: "break-word",
                                          textAlign: "center", // Added this line to center the text
                                          alignItems: "center",
                                          position: "sticky",
                                          top: 0,
                                          zIndex: 10,
                                          backgroundColor: "#f5f5f5",
                                        }}
                                      >
                                        {column.render("Header")}
                                      </th>
                                    )
                                  )}
                                  <th>🗑️</th>{" "}
                                  {/* Add Action header for the delete icon */}
                                </tr>
                              )
                            )}
                          </thead>
                          <tbody
                            {...getTableBodyProps()}
                            style={{ fontSize: "12px" }}
                          >
                            {rows.map((row, rowIndex) => {
                              prepareRow(row);
                              return (
                                <tr
                                  {...row.getRowProps()}
                                  key={`row-${rowIndex}`}
                                  style={{ height: "30px" }}
                                >
                                  {/* SKU Name Column First */}
                                  {row.cells
                                    .filter(
                                      (cell) =>
                                        cell.column.id === "sku_name" ||
                                        cell.column.id === "add_on_sku"
                                    )
                                    .map((cell, cellIndex) => (
                                      <td
                                        {...cell.getCellProps()}
                                        key={`cell-${rowIndex}-${cellIndex}`}
                                        style={{
                                          padding: "4px 8px",
                                          verticalAlign: "middle",
                                          wordWrap: "break-word",
                                        }}
                                      >
                                        <div
                                          style={{
                                            display: "inline-block",
                                            width: "auto",
                                          }}
                                        >
                                          {/* {loadingSku ? (
                                            <>
                                              <CircularProgress
                                                size={80}
                                                style={{ color: "#318CE7" }}
                                              />{" "}
                                            </>
                                          ) : null} */}
                                          {cell.column.id === "sku_name" ? (
                                            <div>
                                              <CustomDropdown
                                                options={
                                                  loadingSku
                                                    ? ["Loading..."] // show loader text
                                                    : results.map((item) => item.sku_name)
                                                }
                                                value={
                                                  tableData[rowIndex]?.[
                                                    cell.column.id
                                                  ] || ""
                                                }
                                                onChange={(selectedValue) => {
                                                  if (selectedValue === "Loading...") return; // Prevent selection of "Loading..."
                                                  // Find the corresponding sku for the selected sku_name
                                                  const selectedSku =
                                                    results.find(
                                                      (item) =>
                                                        item.sku_name ===
                                                        selectedValue
                                                    )?.sku;

                                                  const updatedTableData = [
                                                    ...tableData,
                                                  ];
                                                  updatedTableData[rowIndex][
                                                    cell.column.id
                                                  ] = selectedValue;
                                                  updatedTableData[
                                                    rowIndex
                                                  ].sku = selectedSku || ""; // If no sku found, set empty

                                                  updatedTableData[
                                                    rowIndex
                                                  ].add_on_sku = "";
                                                  setTableData(
                                                    updatedTableData
                                                  );
                                                }}
                                              />

                                              {tableData[rowIndex]?.[
                                                cell.column.id
                                              ] &&
                                                !results.some(
                                                  (item) =>
                                                    item.sku_name ===
                                                    tableData[rowIndex][
                                                      cell.column.id
                                                    ].value
                                                ) && (
                                                  <div
                                                    style={{
                                                      color: "#FFC72C",
                                                      marginTop: "3px",
                                                      fontSize: "11px",
                                                    }}
                                                  >
                                                    {
                                                      tableData[rowIndex][
                                                        cell.column.id
                                                      ].value
                                                    }
                                                  </div>
                                                )}

                                              {validationErrors.some(
                                                (error) =>
                                                  error.rowIndex === rowIndex &&
                                                  error.columnId === "sku_name"
                                              ) && (
                                                <div
                                                  style={{
                                                    color: "red",
                                                    fontSize: "11px",
                                                    marginTop: "3px",
                                                  }}
                                                >
                                                  {
                                                    validationErrors.find(
                                                      (error) =>
                                                        error.rowIndex ===
                                                          rowIndex &&
                                                        error.columnId ===
                                                          "sku_name"
                                                    )?.message
                                                  }
                                                </div>
                                              )}

                                              {/* Display the add_on_sku value below the dropdown if it exists */}
                                              {tableData[rowIndex]
                                                ?.add_on_sku && (
                                                <div
                                                  style={{
                                                    fontSize: "11px",
                                                    color: "red",
                                                    fontWeight: "bold",
                                                    marginTop: "5px", // Optional: Add some spacing between the dropdown and add_on_sku
                                                  }}
                                                >
                                                  Add-on SKU:{" "}
                                                  {
                                                    tableData[rowIndex]
                                                      .add_on_sku
                                                  }
                                                </div>
                                              )}
                                            </div>
                                          ) : (
                                            <div>
                                              {/* In case this cell is for add_on_sku, display it here */}
                                              <div
                                                style={{
                                                  fontSize: "14px",
                                                  fontWeight: "bold",
                                                }}
                                              >
                                                {tableData[rowIndex]
                                                  ?.add_on_sku ||
                                                  "No Add-on SKU"}
                                              </div>
                                            </div>
                                          )}
                                        </div>
                                      </td>
                                    ))}

                                  {/* Other Columns */}

                                  {row.cells
                                    .filter(
                                      (cell) => cell.column.id !== "sku_name"
                                    )
                                    .map((cell, cellIndex) => (
                                      <td
                                        {...cell.getCellProps()}
                                        key={`cell-${rowIndex}-${cellIndex}`}
                                        style={{
                                          padding: "4px 8px",
                                          verticalAlign: "middle",
                                          wordWrap: "break-word",
                                        }}
                                      >
                                        <div
                                          style={{
                                            display: "inline-block",
                                            width: "auto",
                                          }}
                                        >
                                          <>
                                            <span
                                              style={{
                                                visibility: "hidden",
                                                position: "absolute",
                                                padding: "0",
                                                fontFamily: "inherit",
                                                fontSize: "inherit",
                                              }}
                                              id={`span-${rowIndex}-${cell.column.id}`}
                                            >
                                              {"6"}
                                            </span>

                                            {cell.value?.type ===
                                              "dropdown" && (
                                              <select
                                                // value={
                                                //   tableData[rowIndex]?.[
                                                //     cell.column.id
                                                //   ]?.value || "6"
                                                // }
                                                value={
                                                  tableData[rowIndex]?.[
                                                    cell.column.id
                                                  ]?.value || "" // Default to an empty value if not set
                                                }
                                                onChange={(event) => {
                                                  const value =
                                                    event.target.value;
                                                  handleInputChange(
                                                    event,
                                                    rowIndex,
                                                    cell.column.id,
                                                    value,
                                                    "dropdown"
                                                  );
                                                }}
                                                style={{
                                                  fontSize: "12px",
                                                  padding: "2px",

                                                  borderBottom:
                                                    validationErrors.some(
                                                      (error) =>
                                                        error.rowIndex ===
                                                          rowIndex &&
                                                        error.columnId ===
                                                          cell.column.id
                                                    )
                                                      ? "1px solid red"
                                                      : "1px solid #ccc",
                                                  textAlign: "center",
                                                  width: "70px",
                                                }}
                                              >
                                                {cell.value?.validations
                                                  ?.choices &&
                                                  cell.value.validations.choices
                                                    .split(";")
                                                    .map((choice, index) => (
                                                      <option
                                                        key={index}
                                                        value={choice.trim()}
                                                      >
                                                        {choice.trim()}
                                                      </option>
                                                    ))}
                                              </select>
                                            )}

                                            {cell.value?.type === "number" && (
                                              <input
                                                type="text"
                                                value={
                                                  tableData[rowIndex]?.[
                                                    cell.column.id
                                                  ]?.value || ""
                                                }
                                                onChange={(event) =>
                                                  handleInputChange(
                                                    event,
                                                    rowIndex,
                                                    cell.column.id,
                                                    event.target.value,
                                                    "number"
                                                  )
                                                }
                                                style={{
                                                  fontSize: "12px",
                                                  padding: "2px 4px",
                                                  borderBottom:
                                                    validationErrors.some(
                                                      (error) =>
                                                        error.rowIndex ===
                                                          rowIndex &&
                                                        error.columnId ===
                                                          cell.column.id
                                                    )
                                                      ? "1px solid red"
                                                      : "1px solid #ccc",
                                                  textAlign: "center",
                                                  width: "100%",
                                                }}
                                                className="line-input"
                                                placeholder={
                                                  tableData[rowIndex]?.[
                                                    cell.column.id
                                                  ]
                                                    ? "Enter Value"
                                                    : "Enter Value"
                                                }
                                                title={
                                                  tableData[rowIndex]?.[
                                                    cell.column.id
                                                  ] || "Enter Value"
                                                }
                                              />
                                            )}

                                            {cell.value?.type ===
                                              "multiple_image" && (
                                              <input
                                                type="text"
                                                value={
                                                  tableData[rowIndex]?.[
                                                    cell.column.id
                                                  ]?.value || ""
                                                }
                                                onChange={(event) =>
                                                  handleInputChange(
                                                    event,
                                                    rowIndex,
                                                    cell.column.id,
                                                    event.target.value,
                                                    "multiple_image"
                                                  )
                                                }
                                                style={{
                                                  fontSize: "12px",
                                                  padding: "2px 4px",
                                                  borderBottom:
                                                    validationErrors.some(
                                                      (error) =>
                                                        error.rowIndex ===
                                                          rowIndex &&
                                                        error.columnId ===
                                                          cell.column.id
                                                    )
                                                      ? "1px solid red"
                                                      : "1px solid #ccc",
                                                  textAlign: "center",
                                                  width: "100%",
                                                }}
                                                className="line-input"
                                                placeholder={
                                                  tableData[rowIndex]?.[
                                                    cell.column.id
                                                  ]
                                                    ? "Enter Value"
                                                    : "Enter Value"
                                                }
                                                title={
                                                  tableData[rowIndex]?.[
                                                    cell.column.id
                                                  ] || "Enter Value"
                                                }
                                              />
                                            )}

                                            {cell.value?.type === "date" && (
                                              <input
                                                type="date"
                                                value={
                                                  tableData[rowIndex]?.[
                                                    cell.column.id
                                                  ].value || ""
                                                }
                                                onChange={(event) =>
                                                  handleInputChange(
                                                    event,
                                                    rowIndex,
                                                    cell.column.id,
                                                    event.target.value,
                                                    "date"
                                                  )
                                                }
                                                style={{
                                                  fontSize: "12px",
                                                  padding: "2px 4px",
                                                  borderBottom:
                                                    validationErrors.some(
                                                      (error) =>
                                                        error.rowIndex ===
                                                          rowIndex &&
                                                        error.columnId ===
                                                          cell.column.id
                                                    )
                                                      ? "1px solid red"
                                                      : "1px solid #ccc",
                                                  textAlign: "center",
                                                  width: "100%",
                                                }}
                                                className="line-input"
                                                placeholder={
                                                  tableData[rowIndex]?.[
                                                    cell.column.id
                                                  ]
                                                    ? "Enter Value"
                                                    : "Enter Value"
                                                }
                                                title={
                                                  tableData[rowIndex]?.[
                                                    cell.column.id
                                                  ] || "Enter Value"
                                                }
                                              />
                                            )}

                                            {cell.value?.type === "time" && (
                                              <input
                                                type="time"
                                                value={
                                                  tableData[rowIndex]?.[
                                                    cell.column.id
                                                  ].value || ""
                                                }
                                                onChange={(event) =>
                                                  handleInputChange(
                                                    event,
                                                    rowIndex,
                                                    cell.column.id,
                                                    event.target.value,
                                                    "time"
                                                  )
                                                }
                                                style={{
                                                  fontSize: "12px",
                                                  padding: "2px 4px",
                                                  borderBottom:
                                                    validationErrors.some(
                                                      (error) =>
                                                        error.rowIndex ===
                                                          rowIndex &&
                                                        error.columnId ===
                                                          cell.column.id
                                                    )
                                                      ? "1px solid red"
                                                      : "1px solid #ccc",
                                                  textAlign: "center",
                                                  width: "100%",
                                                }}
                                                className="line-input"
                                                placeholder={
                                                  tableData[rowIndex]?.[
                                                    cell.column.id
                                                  ]
                                                    ? "Enter Value"
                                                    : "Enter Value"
                                                }
                                                title={
                                                  tableData[rowIndex]?.[
                                                    cell.column.id
                                                  ] || "Enter Value"
                                                }
                                              />
                                            )}

                                            {cell.value?.type === "text" && (
                                              <input
                                                type="text"
                                                value={
                                                  tableData[rowIndex]?.[
                                                    cell.column.id
                                                  ].value || ""
                                                }
                                                onChange={(event) =>
                                                  handleInputChange(
                                                    event,
                                                    rowIndex,
                                                    cell.column.id,
                                                    event.target.value,
                                                    "text"
                                                  )
                                                }
                                                style={{
                                                  fontSize: "12px",
                                                  padding: "2px 4px",
                                                  borderBottom:
                                                    validationErrors.some(
                                                      (error) =>
                                                        error.rowIndex ===
                                                          rowIndex &&
                                                        error.columnId ===
                                                          cell.column.id
                                                    )
                                                      ? "1px solid red"
                                                      : "1px solid #ccc",
                                                  textAlign: "center",
                                                  width: "100%",
                                                }}
                                                className="line-input"
                                                placeholder={
                                                  tableData[rowIndex]?.[
                                                    cell.column.id
                                                  ]
                                                    ? "Enter Value"
                                                    : "Enter Value"
                                                }
                                                title={
                                                  tableData[rowIndex]?.[
                                                    cell.column.id
                                                  ] || "Enter Value"
                                                }
                                              />
                                            )}

                                            <>
                                              {validationErrors.length === 0 &&
                                                !cell.value && (
                                                  <span
                                                    style={{
                                                      color: "gray",
                                                      fontSize: "12px",
                                                      marginTop: "4px",
                                                      display: "block",
                                                      fontFamily: "barlow",
                                                    }}
                                                  >
                                                    Enter text
                                                  </span>
                                                )}

                                              <hr
                                                style={{
                                                  margin: "0px 0",
                                                  borderColor:
                                                    validationErrors.some(
                                                      (error) =>
                                                        error.rowIndex ===
                                                          rowIndex &&
                                                        error.columnId ===
                                                          cell.column.id
                                                    )
                                                      ? "red"
                                                      : "gray",
                                                  borderWidth: "1px",
                                                }}
                                              />

                                              {/* Show Validation Errors below the HR tag */}
                                              {validationErrors
                                                .filter(
                                                  (error) =>
                                                    error.rowIndex ===
                                                      rowIndex &&
                                                    error.columnId ===
                                                      cell.column.id
                                                )
                                                .map((error, index) => (
                                                  <span
                                                    key={index}
                                                    style={{
                                                      color: "red",
                                                      fontSize: "10px",
                                                      marginTop: "4px",
                                                      display: "block",
                                                      fontFamily: "barlow",
                                                    }}
                                                  >
                                                    {error.message}
                                                  </span>
                                                ))}
                                            </>
                                          </>
                                        </div>
                                      </td>
                                    ))}

                                  <td style={{ textAlign: "center" }}>
                                    <DeleteIcon
                                      style={{
                                        cursor: "pointer",
                                        fontSize: "20px",
                                        color: "grey",
                                      }}
                                      onClick={() => {
                                        if (
                                          tableData[rowIndex]?.isNew == true
                                        ) {
                                          handleOpenDialog(
                                            tableData[rowIndex].id,
                                            rowIndex,
                                            true
                                          );
                                        } else {
                                          handleOpenDialog(
                                            tableData[rowIndex].id,
                                            rowIndex,
                                            false
                                          );
                                        }
                                      }} // Pass skuID and rowIndex here
                                    />
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>

                      {/* Dialog */}
                      <Dialog
                        open={openDialog}
                        onClose={handleCloseDialog}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                      >
                        <DialogTitle id="alert-dialog-title">
                          {"Confirm Deletion"}
                        </DialogTitle>
                        <DialogContent>
                          <DialogContentText id="alert-dialog-description">
                            Are you sure you want to delete this row?
                          </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                          <Button
                            onClick={handleCloseDialog}
                            color="primary"
                            bgColor="lightgrey"
                          >
                            Cancel
                          </Button>
                          <Button
                            onClick={confirmDelete}
                            color="primary"
                            autoFocus
                          >
                            Delete
                          </Button>
                        </DialogActions>
                      </Dialog>
                    </div>
                  )}
                </div>
              </div>
              {enableEditAnswers ? (
                <button
                  className="save-next-button"
                  onClick={handleSaveNext}
                  disabled={hasNewSku || loading} // Disable button when loading
                >
                  {editLoader ? "Please Wait.." : "Save and Next"}{" "}
                  {/* Show loader text */}
                </button>
              ) : null}
            </div>
          )}

          {selectedCard === "new-sku" && (
            <div>
              <p
                style={{
                  fontFamily: "barlow",
                  fontSize: "16px",
                  fontWeight: "700",
                  lineHeight: "20px",
                  textAlign: "left",
                }}
              >
                {/* Add any text here if needed */}
              </p>

              {/* Container to align the button to the right */}
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  marginBottom: "20px",
                }}
              >
                <Button
                  size="medium"
                  bgColor={black100}
                  onClick={handleExportToExcelButtonClick}
                  style={{ marginRight: "10px" }}
                >
                  <span
                    style={{
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      color: "#3E96C8",
                    }}
                  >
                    <ExcelIcon />
                    Export to CSV
                  </span>
                </Button>
              </div>

              <table className="custom-table">
                <thead>
                  <tr>
                    <th>Select</th>
                    <th>New SKU's</th>
                    <th>Manufacturer</th>
                    <th>Brand</th>
                    <th>Variant</th>
                    <th>Sub Category</th>
                  </tr>
                </thead>
                <tbody>
                  {newAddedSKuTest.map((row, rowIndex) =>
                    row.simplifiedinvoiceaddonsku.map((sku, skuIndex) => (
                      <tr key={`${rowIndex}-${skuIndex}`}>
                        <td>
                          <input
                            type="checkbox"
                            onChange={(e) =>
                              handleCheckboxChange(sku.id, e.target.checked)
                            }
                          />
                        </td>
                        <td>{sku.add_on_sku || "N/A"}</td>
                        <td>{sku.manufacturer || "N/A"}</td>
                        <td>{sku.brand || "N/A"}</td>
                        <td>{sku.variant || "N/A"}</td>
                        <td>{sku.sub_category || "N/A"}</td>
                      </tr>
                    ))
                  )}
                </tbody>
              </table>
            </div>
          )}
        </div>
      )}
    </React.Fragment>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    notificationHandler: bindActionCreators(triggerNotification, dispatch),
  };
};

export default connect(null, mapDispatchToProps)(CollectedBills);
