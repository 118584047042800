import React from 'react';
import styled,{ css } from 'styled-components';
import { black100, cinnabar300 } from '../../../theme/colors';
import themeSizes from '../../../theme/themeSizes';
import { baseCaption } from '../../../theme/typography';
import ReactTooltip from "react-tooltip";
import CloseIcon from '@material-ui/icons/Close';

const RowStyle = styled.div`
    min-height:45px;
    margin: ${props => props.margin || '22px 0'};
    @media ${themeSizes.mobile} {
        margin-top: 0px !important;
      }
    display:flex;
    flex-wrap: wrap;
    ${baseCaption}
    ${props => props.noFlexWrap && css`
        flex-wrap:nowrap;
        align-items:${props => props.labelAlign || 'center'};

    `}
    &>span{
        width:13%;
        padding-top:12px;
        @media ${themeSizes.mobile} {
            font-size:12px;
          }
        ${props => props.noFlexWrap && css`
            padding-top:${props => props.labelAlign?'12px':0};
        `}
        ${props => props.isAsterisk && css`
            &:after{
                content:' *';
                color: ${cinnabar300};
            }
        `};
        ${props => props.labelColor && css`
            color: ${props.labelColor};
        `};
        @media ${themeSizes.tablet} {
            width:100% !important;
            margin-bottom:20px;
          }
          
    };
    @media ${themeSizes.tablet} {
        justify-content:flex-start !important;
      }
`;

export const FormRow = ({
name='',
asterisk,
rightElement='',
leftSideWidth = 'auto',
margin,
labelColor,
noFlexWrap=false,
labelAlign,
customStyles,isMandatory,
labelMargin,
toolIcon,
isToolTip,
tooltipData
}) => {
    return (
        <RowStyle 
            margin={margin} 
            isAsterisk={asterisk} 
            labelColor={labelColor} 
            noFlexWrap={noFlexWrap}
            labelAlign={labelAlign}
            style={{...customStyles}}
        >
            <span style={{ width: leftSideWidth, marginLeft: labelMargin?.marginLeft || "0px" }}>
            {name} {isMandatory && <span style={{color:'#E23A3A'}}>*</span>} {isToolTip && 
            <>
            <span data-tip data-for="projectTooltip">
                {toolIcon} 
            </span>
            <ReactTooltip id="projectTooltip" place="top" effect="solid" style={{ width: "20px", height: "100px", margin: "40px" }}>
                {tooltipData}
            </ReactTooltip>
            </>
        }</span>

                
           
            {rightElement}
        </RowStyle>
    )
}