import React from "react";
import { Title } from "./outlet.styles";
import styled from "styled-components";
import {
  microHeading,
  defaultFont,
  regularHeading,
  font,
} from "../../../theme/typography";
import {
  blue300,
  black600,
  curiousBlue300,
  green300,
  white,
} from "../../../theme/colors";
import { xsBold, bold, semiBold } from "../../../theme/typeface";
import DateToDisplay from "../../../helpers/utils/DateToDisplay";
import { FormRow } from "../components/FormRow";
import { Button } from "../../../components/Button";
import SmsIcon from "@material-ui/icons/Sms";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import { StyledTable, THEAD, THD, TBODY, TR, TD  } from "../../../components/table/table";

const Wrapper = styled.div`
  width: 50%;
`;

const TitleWrapper = styled(Title)`
    ${microHeading.regular}
    color:${blue300};
    height:auto;
    margin: 48px 0 24px;
    font-weight:${xsBold};
`;


const AnswerText = styled.div`
  padding-top: 12px;
`;

export const ConfirmationCalling = ({ outletDetails = {}, triggerNotification}) => {

  const { client_payout_month, amount_to_pay, utr_number, payment_date} =
    outletDetails.related_workflow_object || {};
  const { project, program, phone_number1, phone_number2, bank_kyc, branch_kyc, pan_number_kyc, gst_number_kyc, account_number_kyc, ifsc_code_kyc, total_no_of_invoice_pending,latest_transaction_details } = outletDetails;
  
  return (
    <Wrapper>
      <TitleWrapper>
      Details to Inform
      </TitleWrapper>
      <FormRow
        name={"Project Name"}
        leftSideWidth={"50%"}
        rightElement={<AnswerText>{(project || {}).name}</AnswerText>}
      />
      <FormRow
        name={"Program Name"}
        leftSideWidth={"50%"}
        rightElement={<AnswerText>{program?.name || "N/A"} </AnswerText>}
      />
      <FormRow
        name={"Client Payout Month"}
        leftSideWidth={"50%"}
        rightElement={<AnswerText>{client_payout_month || "N/A"}</AnswerText>}
      />
      <FormRow
        name={"Amount"}
        leftSideWidth={"50%"}
        rightElement={<AnswerText>{amount_to_pay || "N/A"}</AnswerText>}
      />
      <FormRow
        name={"UTR Number"}
        leftSideWidth={"50%"}
        rightElement={<AnswerText>{utr_number || "N/A"}</AnswerText>}
      />
      <FormRow
        name={"Payment Date"}
        leftSideWidth={"50%"}
        rightElement={<AnswerText>{payment_date || "N/A"}</AnswerText>}
      />
      <FormRow
        name={"Bank Name"}
        leftSideWidth={"50%"}
        rightElement={<AnswerText>{bank_kyc || "N/A"}</AnswerText>}
      />
      <FormRow
        name={"Total No. of Invoice Pending"}
        leftSideWidth={"50%"}
        rightElement={<AnswerText>{total_no_of_invoice_pending || "N/A"}</AnswerText>}
      />
      <FormRow
        name={"Branch Name"}
        leftSideWidth={"50%"}
        rightElement={<AnswerText>{branch_kyc || "N/A"}</AnswerText>}
      />
      <FormRow
        name={"GST Number"}
        leftSideWidth={"50%"}
        rightElement={<AnswerText>{gst_number_kyc || "N/A"}</AnswerText>}
      />
      <FormRow
        name={"Pan Number"}
        leftSideWidth={"50%"}
        rightElement={<AnswerText>{pan_number_kyc || "N/A"}</AnswerText>}
      />
      <FormRow
        name={"Account Number"}
        leftSideWidth={"50%"}
        rightElement={<AnswerText>{account_number_kyc || "N/A"}</AnswerText>}
      />
      <FormRow
        name={"IFSC Code"}
        leftSideWidth={"50%"}
        rightElement={<AnswerText>{ifsc_code_kyc || "N/A"}</AnswerText>}
      />
      <FormRow
        name={"Latest Transaction Details"}
        leftSideWidth={"50%"}
        
      />
      <StyledTable position="left" length="10px">
        <THEAD>
          <THD>
            Date of Payment
          </THD>
          <THD>
            Base value
          </THD>
          <THD>
            Invoice Value Incl GST
          </THD>
          <THD>
            TDS Amount 
          </THD>
          <THD>
            PAID Amount
          </THD>
          <THD>
            Status
          </THD>
          <THD>
            CMS No
          </THD>
          <THD>
            Remarks
          </THD>
        </THEAD>
        <TBODY>
          {latest_transaction_details?.map((transaction, index) => (
            <TR key={index}>
              <TD>{transaction.date_of_payment || "N/A"}</TD>
              <TD>{transaction.base_value || "N/A"}</TD>
              <TD>{transaction.invoice_value_incl_gst || "N/A"}</TD>
              <TD>{transaction.tds_amt || "N/A"}</TD>
              <TD>{transaction.paid_amt || "N/A"}</TD>
              <TD>{transaction.status || "N/A"}</TD>
              <TD>{transaction.cms_number || "N/A"}</TD>
              <TD>{transaction.remarks || "N/A"}</TD>
            </TR>
          ))}
        </TBODY>
      </StyledTable>
    </Wrapper>
  );
};
