import { AppConfig } from "../../app-config";

export const SIGNUP = `${AppConfig.api_url}auth/dummy_signup/?method_of_login=email_password`;
export const LOGIN = `${AppConfig.api_url}auth/login/`;
export const LOGOUT = `${AppConfig.api_url}auth/logout/`;
export const SEND_CODE = `${AppConfig.api_url}auth/forgot/send-code/`;
export const VERIFY_CODE = `${AppConfig.api_url}auth/forgot/verify-code/`;
export const CHANGE_PASSWORD = `${AppConfig.api_url}auth/forgot/change-password/`;
export const RETAILERS_LOGIN = `${AppConfig.api_url}auth/step-helper/`;
export const RETAILERS_SEND_OTP = `${AppConfig.api_url}auth/forgot/send-code/`;
export const RETAILERS_VERIFY_OTP = `${AppConfig.api_url}auth/login/`;
export const GET_USER_DETAILS = `${AppConfig.api_url}auth/users/`;
export const GET_PREVIOUS_SKU_DETAILS = `${AppConfig.api_url}project/sub-projects/`;
export const GET_GST_DETAILS = `${AppConfig.api_url}auth/invoice-organisations-details/`;
export const GET_CATEGORY_DETAILS = `${AppConfig.api_url}invoice_sku/category/`;
export const GET_ASSIGN_SKU_DETAILS = `${AppConfig.api_url}invoice_sku/assign_sku/`;
export const GET_ASSIGN_INVOICE_HEADER_DETAILS = `${AppConfig.api_url}invoice_sku/assign_invoice_header/`;
export const GET_SUB_CATEGORY_DETAILS = `${AppConfig.api_url}invoice_sku/subcategory/`;
export const GET_SKU_DETAILS = `${AppConfig.api_url}invoice_sku/sku/`;
export const GET_ASSIGN_SKU_TEMPLATE_DETAILS = `${AppConfig.api_url}invoice_sku/assign_sku_template/`;
export const GET_BRAND_DETAILS = `${AppConfig.api_url}invoice_sku/brand/`;
export const GET_VALIDATIONS_DETAILS = `${AppConfig.api_url}invoice_sku/invoice_field_type/`;
export const GET_VARIANTS_DETAILS = `${AppConfig.api_url}invoice_sku/variants/`;
export const GET_PRODUCT_LIST_DETAILS = `${AppConfig.api_url}invoice_sku/product_family/`;
export const GET_BRANDS_FILTER_DETAILS = `${AppConfig.api_url}invoice_sku/brand/`;
export const GET_VARIANT_FILTER_DETAILS = `${AppConfig.api_url}invoice_sku/variants`;
export const GET_CATEGORIES_FILTER_DETAILS = `${AppConfig.api_url}invoice_sku/category/`;
export const GET_SUBCATEGORIES_FILTER_DETAILS = `${AppConfig.api_url}invoice_sku/subcategory/`;
export const GET_MANUFACTURES_DETAILS = `${AppConfig.api_url}invoice_sku/manufacturer/`;
export const GET_INVOICE_DETAILS = `${AppConfig.api_url}invoice_sku/sku_template/`;
export const GET_INVOICE_HEADERS_DETAILS = `${AppConfig.api_url}invoice_sku/invoice_header/`;
export const GET_VIEW_MOBILE_DOWNLOADS_LIST=`${AppConfig.api_url}ezeepay/pp-outlet-list/`;
export const GET_VIEW_MOBILE=`${AppConfig.api_url}ezeepay/pp-outlet-details/`;
export const CREATE_VIEW_MOBILE= `${AppConfig.api_url}ezeepay/pp-outlet-details/`;
export const GET_VIEW_MOBILE_EARNINGS=`${AppConfig.api_url}ezeepay/pp-outlet-earning/`;
export const GET_COLLECTED_BILLS_EARNINGS=`${AppConfig.api_url}invoice_sku/qc_screen_simplified_invoice/`;
export const GET_TOTAL_IMAGE_COLLECTED_BILLS_EARNINGS=`${AppConfig.api_url}invoice_sku/simplified_invoice_all_images/`;//invoice_sku/simplified_invoice_all_images/?workflow_object_id=6338938&no-pagination


export const GET_NEW_SKU_DATA=`${AppConfig.api_url}invoice_sku/simplified_invoice_add_on_sku/`;
export const GET_COLLECTED_BILLS_IMAGES=`${AppConfig.api_url}invoice_sku/simplified_invoice_all_images/`;
export const GET_VIEW_MOBILE_INVOICES=`${AppConfig.api_url}ezeepay/pp-outlet-invoice/`;
export const GET_VIEW_MOBILE_TARGET=`${AppConfig.api_url}ezeepay/pp-performance-statement-project-view/`;
export const GET_ROLE_DETAILS = `${AppConfig.api_url}auth/roles/`;
export const META_DATA_URL = `${AppConfig.api_url}meta/count/`;
export const SIMPLIFIED_META_DATA_URL = `${AppConfig.api_url}invoice_sku/simplified_invoice_dashboard/`;
export const GET_STATES_URL = `${AppConfig.api_url}meta/states/`;
export const GET_CITIES_URL = `${AppConfig.api_url}meta/cities/`;
export const GET_REGIONS = `${AppConfig.api_url}meta/regions/`;
export const GET_ZONES = `${AppConfig.api_url}meta/zones/`;
export const GET_TERRITORIES = `${AppConfig.api_url}meta/territories/`;
export const GET_ROLES = `${AppConfig.api_url}auth/roles/`;
export const GET_COMMON_DATA = `${AppConfig.api_url}common/all-data/`;
export const GET_MANUFACTURE_FILTER_DATA = `${AppConfig.api_url}invoice_sku/manufacturer/?no-pagination&status=active`;
export const GET_PRODUCT_FILTER_DATA = `${AppConfig.api_url}invoice_sku/product_family/?no-pagination&status=active`;//
export const GET_ASSIGN_SKU_FILTER_DATA = `${AppConfig.api_url}invoice_sku/sku/?no-pagination&status=active`;//
export const GET_ASSIGN_SKU_TEMPLATE_FILTER_DATA = `${AppConfig.api_url}invoice_sku/sku_template/?no-pagination&status=active`;//
export const GET_ASSIGN_INOVICE_HEADER_FILTER_DATA = `${AppConfig.api_url}invoice_sku/invoice_header/?no-pagination&status=active`;
export const GET_COMMON_DATA_CACHEED = `${AppConfig.api_url}common/all-data-cached/`;
export const CREATE_USER = `${AppConfig.api_url}auth/users/`;
export const CREATE_ROLE = `${AppConfig.api_url}auth/roles/`;
export const GET_PERMISSIONS = `${AppConfig.api_url}auth/permissions/`;
export const GET_LITE_USERS = `${AppConfig.api_url}common/lite/users/`;
export const SESSION_USER_LOGOUT = `${AppConfig.api_url}auth/users/`;
export const GET_LITE_DATA = `${AppConfig.api_url}common/lite/`;
export const GET_SUB_PROJECTS = `${AppConfig.api_url}common/lite/sub_projects/`
export const GET_FE_OPTIONS_HELPER = `${AppConfig.api_url}common/fe-options-helper/`;
export const CREATE_GST_DETAILS = `${AppConfig.api_url}auth/invoice-organisations-details/`;
export const CREATE_PREVIOUS_SKU_DETAILS = `${AppConfig.api_url}invoice_sku/assign_previous_cycle_sku/`;
export const CREATE_SUBCATEGORY_DETAILS = `${AppConfig.api_url}invoice_sku/subcategory/`;
export const CREATE_PRODUCT_DETAILS = `${AppConfig.api_url}invoice_sku/product_family/`;
export const CREATE_BRANDS_DETAILS = `${AppConfig.api_url}invoice_sku/brand/`;//invoice_sku/variants/
export const CREATE_VARIANTS_DETAILS = `${AppConfig.api_url}invoice_sku/variants/`;
export const CREATE_MANUFACTURES_DETAILS = `${AppConfig.api_url}invoice_sku/manufacturer/`;
export const CREATE_INVOICE_HEADER_DETAILS = `${AppConfig.api_url}invoice_sku/invoice_header/`;
export const CREATE_SKU_DETAILS = `${AppConfig.api_url}invoice_sku/sku/`;
export const CREATE_CATEGORY_DETAILS = `${AppConfig.api_url}invoice_sku/category/`;
export const CREATE_COLLECTED_BILLS_SKU = `${AppConfig.api_url}invoice_sku/qc_screen_simplified_invoice/invoice-edit`;
export const UPDATE_INVOICE_NUMBER = `${AppConfig.api_url}invoice_sku/qc_screen_simplified_invoice/invoice-edit`;
export const CREATE_COLLECTED_BILLS_ADD_SKU_EXCEL = `${AppConfig.api_url}invoice_sku/simplified_invoice_add_on_sku_export/`;
export const CREATE_ASSIGN_SKU_DETAILS = `${AppConfig.api_url}invoice_sku/assign_sku/`;
export const CREATE_ASSIGN_SKU_TEMPLATES_DETAILS = `${AppConfig.api_url}invoice_sku/assign_previous_cycle_sku/`;
export const CREATE_ASSIGN_SKU_TEMPLATES_DETAILS_NEW = `${AppConfig.api_url}invoice_sku/assign_sku_template/`;
export const CREATE_ASSIGN_INVOICE_HEADERS_DETAILS = `${AppConfig.api_url}invoice_sku/assign_invoice_header/`;
export const CREATE_INVOICE_DETAILS = `${AppConfig.api_url}invoice_sku/sku_template/`;
export const UPDATE_INVOICE_DETAILS = `${AppConfig.api_url}invoice_sku/sku_template/invoice_edit/`;
export const GET_RETAILER_EDIT = `${AppConfig.api_url}ezeepay/retailer-request/`
export const CREATE_RETAILER_EDIT = `${AppConfig.api_url}ezeepay/retailer-request/`
export const GET_CHECK_PENDING_REQUEST_RETAILER_EDIT = `${AppConfig.api_url}ezeepay/pending-request-check-retailer-edit/`
// UPLOAD
export const UPLOAD_API = `${AppConfig.api_url}common/images/`;
export const FILE_UPLOAD_API = `${AppConfig.api_url}common/files/`;

// Bulk select action
export const BULK_SELECT_ACTION = `${AppConfig.api_url}bulk/select-actions/`;

// ORG
export const GET_ORGANIZATIONS = `${AppConfig.api_url}auth/organisations/`;
export const GET_HELP_FILE = `${AppConfig.api_url}bulk/upload/help/`;
export const UPLOAD_FILE = `${AppConfig.api_url}bulk/upload/`;
export const GET_USER_INFO = `${AppConfig.api_url}auth/info/`;
export const EXPORT_LIST = `${AppConfig.api_url}bulk/export/`;
export const ASYNC_EXPORT_LIST  = (subProjectId) =>`${AppConfig.api_url}bulk/async-export/?sub_project=${subProjectId}`;
export const ASYNC_EXPORT_LIST_NORMAL = `${AppConfig.api_url}bulk/async-export/`;
//Offer
export const PROJECT_OFFER = `${AppConfig.api_url}project/offers/`;

export const GET_ROLES_LIST = `${GET_LITE_DATA}roles/`;

// PROJECT
export const GET_ALL_PROJECTS = `${AppConfig.api_url}project/projects/`;
export const DELETE_ASSIGN_SKU = `${AppConfig.api_url}invoice_sku/assign_sku/`;//assign_invoice_header
export const DELETE_COLLECTED_BILLS_SKU = `${AppConfig.api_url}invoice_sku/simplified_invoice_sku/`;//assign_invoice_header
export const DELETE_ASSIGN_INVOICE_HEADER = `${AppConfig.api_url}invoice_sku/assign_invoice_header/`;//
export const GET_ALL_SUB_PROJECTS = `${AppConfig.api_url}project/sub-projects/`;
export const GET_ALL_CITIES_LIST = `${AppConfig.api_url}meta/cities/`;//{{host}}/meta/cities/?no-pagination&state=<state_id>&id=<city_id>


export const GET_NOTIFICATIONS = `${AppConfig.api_url}project/notifications/`;
export const CREATE_PROJECT = `${AppConfig.api_url}project/projects/`;
export const GET_PROGRAMS = `${AppConfig.api_url}project/programs/`;
export const GET_PANELS = `${AppConfig.api_url}project/panels/`;

//Question Management
export const GET_QA_PROJECT = `${AppConfig.api_url}common/lite/projects/`;
export const GET_QA_SUB_PROJECT = `${AppConfig.api_url}common/lite/sub_projects/`;
export const GET_QUESTION_GROUPS_META = `${AppConfig.api_url}common/lite/questions/`;
export const SHOW_GROUP_LOGIC = `${AppConfig.api_url}survey/question-groups/`;
export const GET_QUESTION_GROUPS_BASED_SUB_PROJECT = (subProjectId) =>
  `${AppConfig.api_url}common/lite/question_groups/?type=sku_group&sub_project=${subProjectId}`;
export const QA_MANAGEMENT_META = `${AppConfig.api_url}project/sub-projects/<sub_project_slug>/question-creation-meta/`;
export const SURVEY_QUESTION_GROUPS = `${AppConfig.api_url}survey/question-groups/`;
export const SURVEY_QUESTION = `${AppConfig.api_url}survey/questions/`;
export const IMPORT_QUESTIONS = `${AppConfig.api_url}project/sub-projects/<for_sub_project_slug>/import-questions/?sub_project=<from_sub_project_slug>`;

// Outlet Management
export const GET_OUTLET_DATA = `${AppConfig.api_url}bulk/upload/?objects_type=project_outlet_master&config__name=sub_project&config__value=`;
export const GET_BULK_TABLE_DATA = `${AppConfig.api_url}bulk/upload/`;
export const ACTIVATE_OUTLET = (
  uploadId,
  projId,
  key = "project_outlet_master"
) =>
  `${AppConfig.api_url}bulk/upload/${uploadId}/activate/?object=${key}${
    projId ? `&sub_project=${projId}` : ""
  }`;
  export const ACTIVATE_SUBPROJECT_OUTLET = (
    uploadId,
    projId,
    key = "project_outlet_master",
    subProjectId
  ) =>
    `${AppConfig.api_url}bulk/upload/${uploadId}/activate/?object=${key}&sub_project=${subProjectId}`;
export const ACTIVATE_OUTLET_SKU = (
  uploadId,
  projId,
  key = "project_outlet_master",
  question_group
) =>
  `${AppConfig.api_url}bulk/upload/${uploadId}/activate/?object=${key}${
    projId ? `&sub_project=${projId}&question_group=${question_group}` : ""
  }`;
export const CANCEL_OUTLET = (
  uploadId,
  projId,
  key = "project_outlet_master"
) =>
  `${AppConfig.api_url}bulk/upload/${uploadId}/cancel/?object=${key}${
    projId ? `&sub_project=${projId}` : ""
  }`;
  export const CANCEL_SUBPROJECT_OUTLET = (
    uploadId,
    projId,
    key = "project_outlet_master",
    subProjectId,
  ) =>
  `${AppConfig.api_url}bulk/upload/${uploadId}/cancel/?object=${key}&sub_project=${subProjectId}`;
export const CANCEL_OUTLET_SKU = (
  uploadId,
  projId,
  key = "project_outlet_master",
  question_group
) =>
  `${AppConfig.api_url}bulk/upload/${uploadId}/cancel/?object=${key}${
    projId ? `&sub_project=${projId}&question_group=${question_group}` : ""
  }`;
export const GET_OUTLETS = `${AppConfig.api_url}project/outlets/`;
export const GET_FILTER_ID = `${AppConfig.api_url}project/outlets/init-async-filter-data/`
export const GET_OUTLETS_ACTIONS = `${AppConfig.api_url}workflow/workflow-config/`;
export const GET_OUTLETS_ACTIONS_SAVE = `${AppConfig.api_url}workflow/workflow-actions/`;
export const GET_OUTLETS_DETAILS = `${AppConfig.api_url}project/outlets/`;
export const GET_QC_HISTORY = `${AppConfig.api_url}bulk/outlet-workflow-object-answer-actionlogs/?workflow_object_id=`;
export const GET_OUTLET_GROUP_DETAILS = `${AppConfig.api_url}survey/question-answers/`;
export const GET_OUTLET_GROUP = `${AppConfig.api_url}survey/question-groups/`;
export const GET_OUTLET_GROUP_SAVE = `${AppConfig.api_url}bulk/create-update/`;
export const GET_SIMILAR_OUTLETS_ACTIONS = (id) =>
  `${AppConfig.api_url}project/similaroutlets/${id}`;
export const GET_CLIENT_ROUTE_PLAN = `${AppConfig.api_url}workflow/route-plans/`;
export const CLIENT_DOWNLOAD_PLAN = `${AppConfig.api_url}workflow/route-plans/actions/?user_based=true`;
export const GET_IFTU_DASHBOARD_DATA = `${AppConfig.api_url}workflow/payouts/`;
export const GET_CALLING_TRACKER = `${AppConfig.api_url}workflow/calling-trackers/`;
export const GET_INTERIM_DUPLICATE_LIST = `${AppConfig.api_url}project/outlets-duplicate-interim-activation/`;
export const GET_OUTLETS_LOGIC_SCORE = (slug) =>
  `${AppConfig.api_url}project/outlets/${slug}/logic-scored-data/`;
export const ACCEPT_OR_REJECT_FIELD_APP_CHANGES =  (slug) => `${AppConfig.api_url}project/outlets/${slug}/accept-or-reject-field-app-changes/`
// QS - SKU PRODUCTS
export const GET_SKU_PRODUCTS_ADD_QG = (slug) =>
  `${AppConfig.api_url}survey/question-groups/${slug}/create-sku-products/`;
export const GET_SKU_PRODUCTS_UPDATE_QG = (slug) =>
  `${AppConfig.api_url}survey/sku-question-group-products/${slug}/`;
export const GET_SKU_PRODUCTS_QG = (slug) =>
  `${AppConfig.api_url}survey/question-groups/${slug}/get-sku-products/`;

// Dashboard
export const GET_DASHBOARD_DATA = `${AppConfig.api_url}auth/user-dashboard/`;
export const GET_CLIENT_DASHBOARD = (slug) =>
  `${AppConfig.api_url}project/projects/${slug}/top-level-meta-info/`;
export const GET_CLIENT_GRAPH = (slug, type) => {
  if (type === "All" || !type)
    return `${AppConfig.api_url}project/insights/?sub_project_slug=${slug}`
  else if (type)
    return `${AppConfig.api_url}project/insights/?sub_project_slug=${slug}&survey_type=${type}`;
};
export const CHAT_MESSAGE = `${AppConfig.api_url}project/messages/`;
export const QC_MANAGE_ALLOCATION = `${AppConfig.api_url}algorithm/calling-qc-user-allocation-data/`;
export const IFTU_MANAGE_ALLOCATION = `${AppConfig.api_url}algorithm/iftu-user-allocation-data/`;

export const GET_LANGUAGES = `${AppConfig.api_url}common/lite/languages/`;

//Retailers Dashboard
export const RETAILERS_GET_NOTIFICATIONS = `${AppConfig.api_url}project/notifications/`;
export const RETAILERS_GET_INVOICES = `${AppConfig.api_url}workflow/invoices/`;
export const RETAILERS_GET_DASHBOARD = `${AppConfig.api_url}auth/user-dashboard/`;
export const RETAILERS_ORG_EARNINGS = `${AppConfig.api_url}project/my-organisation-based-earnings/`;
export const RETAILERS_OUTLETS = `${AppConfig.api_url}project/outlets/my-pan-number-linked-outlets/`;
export const RETAILERS_ACCEPT_AND_SEND = (slug) =>
  `${AppConfig.api_url}workflow/invoices/${slug}/mark-as-accepted/`;
export const RETAILERS_OUTLET_WORKFLOW_UPDATE = (slug) =>
  `${AppConfig.api_url}project/outlets/${slug}/update-retailer-edited-data-from-qc-screen/`;

//Form 16
export const FORM16_UPLOAD = `${AppConfig.api_url}project/form16/`;
export const FORM16_PAN_COUNT = `${AppConfig.api_url}project/form16/meta-info/`;
export const FORM16_PAN_LIST = `${AppConfig.api_url}auth/users/?role__slug=retailer`;
export const FORM16_STORE_DETAIL = (pan_number) =>
  `${AppConfig.api_url}project/outlets/?pan_number=${pan_number}`;
export const FORM16_PDF = (pan_number) =>
  `${AppConfig.api_url}project/form16/?pan_number=${pan_number}`;
//export const RETAILERS_ORG_EARNINGS =  `${AppConfig.api_url}project/my-organisation-based-earnings/`;

//WhatsApp Messaging Screen
export const SEND_MESSAGE = `${AppConfig.api_url}project/whatsapp-logs/`;
export const MESSAGE_DETAILS = `${AppConfig.api_url}project/whatsapp-logs/`;
export const MESSAGE_SUMMARY = `${AppConfig.api_url}project/whatsapp-log-summaries/`;
export const MESSAGES_BULK_UPLOAD = `${AppConfig.api_url}bulk/upload/?object=utility_whatsapp_messaging`;
export const MESSAGES_BULK_ACTIVATE = `${AppConfig.api_url}bulk/upload/<bulk_object_id>/activate/?`;

//Reoprts
export const MIS_REPORTS_CONFIG = `${AppConfig.api_url}project/mis-reports/config/`;
export const MIS_REPORTS = `${AppConfig.api_url}project/mis-reports/`;
export const MIS_SUMMARY = `${AppConfig.api_url}project/mis-summary-dashboard/`;
export const MIS_LINKED_USERS = `${AppConfig.api_url}common/lite/users/`;

export const LOGIC_SCORE_GROUP = `${AppConfig.api_url}survey/logic-score-groups/`;
export const LOGIC_SCORE_SUB_GROUP = `${AppConfig.api_url}survey/logic-score-sub-groups/`;
export const LOGIC_SCORE_SUB_GROUP_CREATE_DELETE = `${AppConfig.api_url}survey/logic-score-sub-group-rule-conditions-bulk-create-update/`;
export const LOGIC_SCORE_SUB_GROUP_RULES = `${AppConfig.api_url}survey/logic-score-sub-group-rules/`;
export const IFSC_VERIFICATION = (ifsc) =>
  `${AppConfig.api_url}workflow/ifsc-codes/${ifsc}/`;
export const PAN_AND_GST_VERIFICATION = `${AppConfig.api_url}common/pan-and-gst-validity-verification/`;
export const YES_BANK_ACCOUNT_VERIFICATION = `${AppConfig.api_url}v2/yes-bank/account-verification/`;
export const UPI_VERIFICATION = `${AppConfig.api_url}common/validate-upi-id/`;
export const ORG_SEARCH = `${AppConfig.api_url}common/lite/organisations/?search=`;
export const LOGOUT_ALL_SESSION = `${AppConfig.api_url}auth/logout/all/?ignore-current-session=true`;
export const CHECK_DISTRIBUTOR_GST = `${AppConfig.api_url}bulk/distributor-gst/`
// TDS
export const TDS_REPORTS = `${AppConfig.api_url}v2/tds/reports/`;

//My Downloads
export const MY_DOWNLOADS = `${AppConfig.api_url}bulk/my-async-export/`;

// Unicorn

export const UNICORN_LIST = `${AppConfig.api_url}project/unicorn-trackers/`;
export const UNICORN_INDIVIDUAL_OUTLET = (item_id) =>
  `${AppConfig.api_url}project/outlets/?organisation_tracker__unicorn_tracker=${item_id}`;

// Outlet Filter
export const OUTLET_FILTER_V2 = `${AppConfig.api_url}v2/outlet/filter-data-read/`;
export const OUTLET_FILTER_DATA_V2 = (id) =>
  `${AppConfig.api_url}v2/common/load-objects/${id}/`;

// Sku Labels
export const GET_SKU_LABELS = (questionSlug) =>
  `${AppConfig.api_url}survey/question-groups/${questionSlug}/get-sku-labels/`;
export const CREATE_SKU_LABEL = (questionSlug) =>
  `${AppConfig.api_url}survey/question-groups/${questionSlug}/create-sku-labels/`;
export const UPDATE_SKU_LABEL = (labelSlug) =>
  `${AppConfig.api_url}survey/sku-question-group-labels/${labelSlug}/`;

// Meta City Duplicates
export const META_CITY_DUPLICATES_LIST = `${AppConfig.api_url}meta/cities-similarity-tracker/`;
export const META_CITY_MERGE = (trackerId) =>
  `${AppConfig.api_url}meta/cities-similarity-tracker/${trackerId}/merge-sources-to-destination/`;
export const META_CITY_MERGE_V2 = `${AppConfig.api_url}meta/cities-similarity-tracker/meta-merge/`;

// Logic Score
export const LOGIC_SCORE_HOME_TABLE_DETAILS = (subProjectId) =>
  `${AppConfig.api_url}logic-scoring/home-page-data/?sub_project=${subProjectId}`;
export const LOGIC_SCORE_SCORING_PARAMETERS = (subProjectId, survey_type="") =>
  `${AppConfig.api_url}logic-scoring/scoring-parameters/?sub_project=${subProjectId}&survey_type=${survey_type}&no-pagination`;
export const LOGIC_SCORE_SCORING_PARAMETERS_WITH_ID = (id) =>
  `${AppConfig.api_url}logic-scoring/scoring-parameters/${id}/`;
export const LOGIC_SCORE_CALCULATED_PARAMETERS = (subProjectId, survey_type="") =>
  `${AppConfig.api_url}logic-scoring/calculated-parameter-groups/?sub_project=${subProjectId}&survey_type=${survey_type}&no-pagination`;
export const LOGIC_SCORE_CALCULATED_PARAMETERS_WITH_ID = (id) =>
  `${AppConfig.api_url}logic-scoring/calculated-parameter-groups/${id}/`;
export const LOGIC_SCORE_CALCULATED_PARAMETERS_SUB_GROUP = (group) =>
  `${AppConfig.api_url}logic-scoring/calculated-parameter-sub-groups/?group=${group}`;
export const LOGIC_SCORE_CALCULATED_PARAMETERS_SUB_GROUP_WITH_ID = (id) =>
  `${AppConfig.api_url}logic-scoring/calculated-parameter-sub-groups/${id}/`;
export const LOGIC_SCORE_SUB_PROJECT_META_DATA = (id) =>
  `${AppConfig.api_url}logic-scoring/sub-project-meta-data/${id}/`;
export const LOGIC_SCORE_BENCHMARKS_DATA = (subProjectId) =>
  `${AppConfig.api_url}logic-scoring/bench-marks-data/?sub_project=${subProjectId}`;
export const LOGIC_SCORE_BENCHMARKS_DATA_WITH_ID = (id) =>
  `${AppConfig.api_url}logic-scoring/bench-marks-data/${id}/`;
export const LOGIC_SCORE_CLIENT_DATA = (slug) =>
  `${AppConfig.api_url}logic-scoring/outlet/${slug}/client-side-calculated-data/`;

// KYC REPORT
export const CLIENT_KYC_REPORT = (subProjectId) =>
  `${AppConfig.api_url}project/reports/dynamic/kyc-report/?sub_project=${subProjectId}`;
export const CLIENT_KYC_REPORT_IMAGES = (id) =>
  `${AppConfig.api_url}project/reports/dynamic/kyc-report/?id=${id}`;
export const CLIENT_INDIVIDUAL_PAYOUT_DETAILS = (outletId) =>
  `${AppConfig.api_url}project/reports/dynamic/${outletId}/individual-payout/`;
  export const CLIENT_INDIVIDUAL_PAYOUT_DETAILS_SEARCH = (outletId,date) =>
  `${AppConfig.api_url}project/reports/dynamic/${outletId}/individual-payout/${date}`;
export const CLIENT_PAYOUT_REPORT = (subProjectId) =>
  `${AppConfig.api_url}project/reports/dynamic/overall-payout-report/?sub_project=${subProjectId}`;
export const CLIENT_PAYOUT_REPORT_AMOUNT_PAID_SPLIT = (rsid) =>
  `${AppConfig.api_url}project/reports/dynamic/overall-payout-report/amount-paid-split/?rsid=${rsid}`;

export const CLIENT_TOTAL_KYC_REPORT = `${AppConfig.api_url}project/reports/dynamic/kyc-report/?only_from_assigned_project=1&sub_project__type=kyc&show_invoice_details=true&show_valid_invoices=true`;
export const CLIENT_TOTAL_PAYOUT_REPORT = `${AppConfig.api_url}project/reports/dynamic/overall-payout-report/?only_from_assigned_project=1&show_invoice_details=true&show_valid_invoices=true`;
export const TMP_CLIENT_KYC_REPORT = `${AppConfig.api_url}project/reports/dynamic/kyc-report/?workflow_state=accepted&page=6`;

// Retail Audit Report
export const RETAIL_AUDIT_REPORT = `${AppConfig.api_url}project/reports/dynamic/retail-audit-report/`
export const RETAIL_AUDIT_REPORT_METADATA = `${AppConfig.api_url}project/reports/dynamic/retail-audit-report/metadata/`
export const RETAIL_AUDIT_REPORT_IMAGES = (id) => `${AppConfig.api_url}project/reports/dynamic/retail-audit-report/?id=${id}`
export const RETAIL_AUDIT_REPORT_EXPORT_CSV = (params) => `${AppConfig.api_url}project/reports/dynamic/retail-audit-report/csv-export/?${params}`
// App Field Dump
export const APP_FIELD_DUMP_TABLE = `${AppConfig.api_url}v2/my-track/offline-dump-upload/`;


// Outlet Recovery
export const OUTLET_RECOVERY_PROJECT = `${AppConfig.api_url}project/ongoing-list/`;
export const OUTLET_RECOVERY_SUBPROJECT = `${AppConfig.api_url}project/get-subprojects-list/`;
export const OUTLET_RECOVERY_AUDITOR= `${AppConfig.api_url}project/get-auditors-list/`;

//Cancel Download
export const CANCEL_DOWNLOAD= `${AppConfig.api_url}bulk/my-downloads-cancel/`;



export const GET_OUTLET_RECOVERY_TABLE_DATA = `${AppConfig.api_url}v2/outlet/recovery-list/?`;
export const GET_OUTLET_RECOVERY_ADDED_TABLE_DATA = `${AppConfig.api_url}v2/outlet/recovery-start/`;
export const GET_OUTLET_RECOVERY_ACCEPT_STATUS = `${AppConfig.api_url}v2/outlet/recovery-accept/`;
export const GET_OUTLET_RECOVERY_REJECT_STATUS = `${AppConfig.api_url}v2/outlet/recovery-reject/`;

export const GET_OUTLET_RECOVERY_DOWNLOAD_TABLE_DATA = `${AppConfig.api_url}v2/outlet/recovery-download-report/`;
// Icici Upi Verification
export const GET_ICICI_INSTANCE_DATA = (id) => `${AppConfig.api_url}bulk/upload/${id}/get-icici-upi-activation-info/?object=icici_upi_account_payment_tracker`

export const MARK_INVOICES_AS_INACTIVE = `${AppConfig.api_url}workflow/invoices/mark-as-inactive/`


export const BULK_EXPORT_QUESTION_MANAGEMENT = (subProjectId) => `${AppConfig.api_url}bulk/async-export/?sub_project=${subProjectId}`

