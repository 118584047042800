import React, { useState, useMemo, useEffect, useRef } from 'react';
import { useTable } from 'react-table';
import { getRetailerRequests, getRetailerRequestData } from '../../../actions/creation/getRetailerEditScreen';
import { createAcceptRequest, createRejectRequest } from '../../../actions/creation/createRetailerEditScreen';
import {
  status_data,
  type_data,
  store_fields_data,
  bank_fields_data,
  tax_fields_data,
  image_type
} from './object';
import './RetailerEditScreen.css';
import ImageMagnifier from './ImageMagnifier';
import FullscreenIcon from '@material-ui/icons/Fullscreen';
import PhotoViewer from '../../dashboard/outlets/imageRetailerEdit';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';

import { WORKFLOW_KEYS } from '../../../helpers/constants/workflowKeys'

// RequestList Component
const RequestList = ({ activeRequestId, onRequestClick, requests }) => {

  const requestListRef = useRef(null);

  const scrollLeft = () => {
    if (requestListRef.current) {
      const listWidth = requestListRef.current.clientWidth;
      const scrollAmount = listWidth * 0.2; 
      requestListRef.current.scrollLeft -= scrollAmount;
    }
  };

  const scrollRight = () => {
    if (requestListRef.current) {
      const listWidth = requestListRef.current.clientWidth;
      const scrollAmount = listWidth * 0.2;
      requestListRef.current.scrollLeft += scrollAmount;
    }
  };

  return (
    <div className='request-list-container'>
      <ArrowBackIosIcon className='arrow-icon-left-request-list'  onClick={scrollLeft}/>
        <div className='request-list' ref={requestListRef}>
          {requests.map((request) => (
            <div
              className={`request-box ${activeRequestId === request.retailer_master_request_id ? 'active' : ''}`}
              key={request.retailer_master_request_id}
              onClick={() => onRequestClick(request.retailer_master_request_id)}
            >
              {request.retailer_master_request_id}
              <div className={`status-circle ${request.retailer_master_status}`} />
            </div>
          ))}
        </div>
        <ArrowForwardIosIcon className='arrow-icon-right-request-list'  onClick={scrollRight}/>
      </div>
  )
};

// RetailerEdit Component
const RetailerEdit = ({ outlet_details, workflow_permission, workflow_state, isUpdateActionListAvailable=true, workflowStateChanged }) => {
  const [requests, setRequests] = useState([]);
  const [activeRequestId, setActiveRequestId] = useState(null);
  const [requestType, setRequestType] = useState('');
  const [requestStatus, setRequestStatus] = useState('');
  const [requestDate, setRequestDate] = useState('');
  const [requestTime, setRequestTime] = useState('');
  const [requestData, setRequestData] = useState([]);
  const [reasonForChange, setReasonForChange] = useState('');
  const [showPopup, setShowPopup] = useState(false);
  const [popupData, setPopupData] = useState('');
  const [selectedReason, setSelectedReason] = useState('');
  const [loading, setLoading] = useState(true);
  const [requestLoading, setRequestLoading] = useState(false);
  const [error, setError] = useState(null);
  const [showImage, setShowImage] = useState(false);
  const [images, setImages] = useState([]); 
  const [imageIndex, setImageIndex] = useState(0);
  const [errorMessage, setErrorMessage] = useState('');

  
  const handleImageClick = (index) => {
    setShowImage(!showImage);
    setImageIndex(index);
  };

  const handleCloseImage = () => {
    setShowImage(false);
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  const formatTime = (dateString) => {
    const date = new Date(dateString);
    let hours = date.getHours();
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12 || 12;
    return `${String(hours).padStart(2, '0')}:${minutes}${ampm}`;
  };

  // Load initial requests
  useEffect(() => {
    loadRequests();
  }, []);

  const loadRequests = async () => {
    setLoading(true);
    setError(null);
    try {
      const req = outlet_details?.workflow_object;
      const response = await getRetailerRequests(req);
      setRequests(response);
      if (response.length > 0) {
        const latestRequest = response[0];
        setActiveRequestId(latestRequest.retailer_master_request_id);
        setRequestType(latestRequest.retailer_master_type);
        setRequestStatus(latestRequest.retailer_master_status);
        setRequestDate(formatDate(latestRequest.retailer_master_created));
        setRequestTime(formatTime(latestRequest.retailer_master_created));
      }
    } catch (error) {
      console.error("Error fetching requests:", error);
      setError("Failed to load requests. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  // Load additional data when activeRequestId changes
  useEffect(() => {
    const loadRequestData = async () => {
      if (!activeRequestId) return;

      setRequestLoading(true);
      setError(null);
      try {
        const req = outlet_details?.workflow_object;
        const encodedRequestId = encodeURIComponent(activeRequestId);
        const dataResponse = await getRetailerRequestData(encodedRequestId, req);
        setRequestData(dataResponse);
        const type_of_request = dataResponse?.retailer_request?.type;
        const reasonKey = `reason_for_${type_of_request}_change`;
        setReasonForChange(dataResponse?.new_data[reasonKey]);
        extractImages(dataResponse); // Extract images from the response
      } catch (error) {
        console.error("Error fetching request data:", error);
        setError("Failed to load request data. Please try again.");
      } finally {
        setRequestLoading(false);
      }
    };

    loadRequestData();
  }, [activeRequestId]);

  const extractImages = (data) => {
  let extractedImages = [];
  if (data?.image_data) {
    Object.entries(data.image_data).forEach(([key, item]) => {
      if (item?.length > 0) {
        extractedImages.push({ title: key, images: item.map(image => image.image) });
      }
    });
  }
  setImages(extractedImages);
};

  // Handle request click
  const handleRequestClick = (requestId) => {
    const clickedRequest = requests.find((request) => request.retailer_master_request_id === requestId);
    if (clickedRequest) {
      setActiveRequestId(requestId);
      setRequestType(clickedRequest.retailer_master_type);
      setRequestStatus(clickedRequest.retailer_master_status);
      setRequestDate(formatDate(clickedRequest.retailer_master_created));
      setRequestTime(formatTime(clickedRequest.retailer_master_created));
    }
  };

  // Prepare data for the table
  const data = useMemo(() => {
    let fieldsData, currentData, newData;

    switch (requestType) {
      case 'store_details':
          fieldsData = store_fields_data;
          currentData = { 
              ...requestData?.old_data?.outlet, 
              ...requestData?.old_data?.meta_data,
              address_proof_images: requestData?.image_data?.address_proof_images_data?.map(item => item.image) || [],
              dealer_board_images: requestData?.old_data?.dealer_board_images_data?.map(item => item.image) || []
          };

          if (currentData.phone_number1) {
            currentData.phone_number = currentData.phone_number1;
            delete currentData.phone_number1;
          }
          if (currentData.phone_number2) {
            currentData.alternate_phone_number = currentData.phone_number2;
            delete currentData.phone_number2;
          }

          newData = requestData?.new_data || {};
          console.log('Store Details:', currentData);
          break;
  
      case 'bank_details':
          fieldsData = bank_fields_data;
          currentData = { 
              ...requestData?.old_data?.outlet, 
              ...requestData?.old_data?.meta_data, 
              ...requestData?.old_data?.workflow_object, 
              bank_proof_images: requestData?.image_data?.upload_bank_proof_images_data?.map(item => item.image) || [],
              ownership_proof_images: requestData?.image_data?.upload_ownership_proof_images_data?.map(item => item.image) || []
          };
          
          if(currentData.phone_number1) {
            currentData.phone_number = currentData.phone_number1;
            delete currentData.phone_number1;
          }
          if(currentData.bank_account_type){
            currentData.account_type = currentData.bank_account_type;
            delete currentData.bank_account_type;
          }
          if(currentData.bank) {
            currentData.branch_name = currentData.bank;
            delete currentData.bank;
          }
          if(currentData.branch) {
            currentData.branch_location = currentData.branch;
            delete currentData.branch;
          }

          newData = requestData?.new_data || {};
          break;
  
      case 'tax_details':
          fieldsData = tax_fields_data;
          currentData = { 
              gst_certificate_proof_images: requestData?.image_data?.gst_certificate_proof_images_data?.map(item => item.image) || [],
              pan_certificate_proof_images: requestData?.image_data?.pan_certificate_proof_images_data?.map(item => item.image) || [],
              ...requestData?.old_data?.outlet, 
              ...requestData?.old_data?.answers.reduce((acc, answer) => {
                  if (answer.question_type === 'pan_number') {
                      acc.pan_holder_name = answer.other_pan_holder_name;
                  } else if (answer.question_type === 'gst_number') {
                      acc.gst_trade_name = answer.other_gst_trade_name;
                      acc.gst_legal_name = answer.other_gst_legal_name;
                  }
                  return acc;
              }, {})
          };
          newData = requestData?.new_data || {};
          console.log('Tax Details:', currentData);
          break;
  
      default:
        console.warn(`Unrecognized request type: ${requestType}`);
        return [];
    }

    if (!fieldsData) {
      console.error("fieldsData is undefined", requestType);
      return [];
    }

    return Object.keys(fieldsData).map((fieldKey) => ({
      col1: fieldsData[fieldKey],
      col2: currentData[fieldKey] || '',
      col3: newData[fieldKey] || '',
    }));
  }, [requestType, requestData]);

  // Define columns for the table
  const columns = useMemo(
    () => [
      { Header: 'Fields', accessor: 'col1' },
      { Header: 'Current Details', accessor: 'col2' },
      { Header: 'New Details', accessor: 'col3' },
    ],
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({ columns, data });

  const getDisplayText = (text) => (
    text.length > 23 ? `${text.slice(0, 23)}....` : text
  );

  const imagesLoading = images.length === 0;

  const checkPermission = (
    ([WORKFLOW_KEYS.QC_MANAGER,WORKFLOW_KEYS.QC_EXECUTIVE].includes(workflow_permission) || 
    (workflow_permission === WORKFLOW_KEYS.PROJECT_MANAGER && workflow_state === "qc_accepted" || workflow_state === "wip_client_accepted" || workflow_state === "calling_accepted")) 
    ? ((workflow_permission === WORKFLOW_KEYS.QC_EXECUTIVE && workflow_state !== "qc_pending") 
        ? false 
        : ((!workflowStateChanged) ? (isUpdateActionListAvailable || WORKFLOW_KEYS.PROJECT_MANAGER) : false)) 
    : false
  );

  const handleAccept = () => {
    setPopupData({ type: 'accept' });
    setShowPopup(true);
  };

  const handleReject = () => {
    setPopupData({ type: 'reject' });
    setShowPopup(true);
  };

  const closePopup = () => {
    setErrorMessage('');
    setSelectedReason('');
    setShowPopup(false);
  };

  const handleChange = (event) => {
    const value = event.target.value;
    if (value === "") {
      return;
    }
    setSelectedReason(value);
  };

  const handleAcceptRequest = async () => {
    try {
      await createAcceptRequest(activeRequestId);
      setShowPopup(false);
      setRequests([]);
      await loadRequests();
    } catch (error) {
      console.error('Error accepting request:', error);
    }
  };

  const handleRejectRequest = async () => {
    if (!selectedReason) {
      setErrorMessage('Please Choose a Rejection Reason');
      return;
    }
    try {
      await createRejectRequest(activeRequestId, selectedReason);
      setShowPopup(false);
      setRequests([]);
      await loadRequests();
    } catch (error) {
      console.error('Error rejecting request:', error);
    }
  }

  const handleSelectChange = (event) => {
    const value = event.target.value;
    setSelectedReason(value);

    if (value === '') {
      setErrorMessage('Please choose a rejection reason.');
    } else {
      setErrorMessage('');
    }
  };

  return (
    <div>
      {loading ? (
        <div className="spinner"></div>
      ) : error ? (
        <p className="error-message">{error}</p>
      ) : requests.length === 0 ? (
        <p className="no-requests-message">No Requests Available</p>
      ) : (
        <>
          <RequestList 
            activeRequestId={activeRequestId} 
            onRequestClick={handleRequestClick} 
            requests={requests} 
          />
          {activeRequestId && (
            <>
              {requestLoading ? (
                <div className="spinner"></div>
              ) : (
                <>
                  <div className='type-header'>
                    <h3>{type_data[requestType]} Edit Request</h3>
                    <p className={`status-box ${requestStatus}`}>
                      {status_data[requestStatus]}
                    </p>
                  </div>

                  <div className='date-image-type-box'> 
                    <p className='date-box'>Raised on {requestDate} at {requestTime}</p>
                    {/* <p className="image-type">{type_data[requestType]} Images</p> */}
                  </div>

                  {/* Flex container for table and image view */}
                  <div className='content-container'>
                    <div className='table-container-retailer-edit'>
                      <table {...getTableProps()} className='table'>
                        <thead>
                          {headerGroups.map((headerGroup) => (
                            <tr {...headerGroup.getHeaderGroupProps()}>
                              {headerGroup.headers.map((column) => (
                                <th {...column.getHeaderProps()} className='table-header'>
                                  {column.render('Header')}
                                </th>
                              ))}
                            </tr>
                          ))}
                        </thead>
                        <tbody {...getTableBodyProps()} className='table-body'>
                          {rows.map((row) => {
                            prepareRow(row);
                            return (
                              <tr {...row.getRowProps()}>
                                {row.cells.map((cell) => (
                                  <td {...cell.getCellProps()} title={cell.value} className='table-cell'>
                                    {getDisplayText(cell.value)}
                                  </td>
                                ))}
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>

                    <div className='partition-line'></div>

                    <div className='image-view'>
                      {imagesLoading ? (
                        <div className="spinner"></div>
                      ) : (
                        <>
                          {images.map((section, index) => (
                            <div key={index}>
                              <h3 class='image-title'>{image_type[section.title]}</h3>
                              {section.images.map((src, imageIndex) => (
                                <div className="image-item" key={imageIndex}>
                                  <ImageMagnifier imageUrl={src} index={imageIndex}>
                                    <img id={`image-${imageIndex}`} src={src} alt={`Image ${imageIndex + 1}`} />
                                  </ImageMagnifier>
                                  <div className='icon-container' onClick={() => handleImageClick(imageIndex)}>
                                    <FullscreenIcon />
                                  </div>
                                </div>
                              ))}
                            </div>
                          ))}
                          {showImage && (
                            <div style={{ marginTop: "10px", flex: "1 0 100%" }}>
                              {images.length > 0 ? (
                                <PhotoViewer
                                  images={images.flatMap(section => section.images)} // Flatten the images array for PhotoViewer
                                  onClose={handleCloseImage}
                                  zoomLevel={0.4}
                                  indexImage={imageIndex}
                                />
                              ) : (
                                <p>No image found</p>
                              )}
                            </div>
                          )}
                        </>
                      )}
                    </div>
                  </div>

                  <div className="reason-container">
                    <p className="reason-text">Reason for {type_data[requestType]} Change</p>
                    <div className="reason-input">
                        {reasonForChange}
                    </div>
                    
                    {requestStatus === 'pending' && checkPermission &&( 
                      <div className='buttons-container'>
                        <div className='accept-button' onClick={handleAccept}>Accept</div>
                        <div className='reject-button' onClick={handleReject}>Reject</div>
                      </div>
                    )}

                    {showPopup && (
                      <div className="popup-container">
                        <div className="popup-content">
                          <div className="popup-header-container">
                            <h3 className="popup-header">{popupData.type === 'accept' ? 'Accept Request' : 'Reject Request'}</h3>
                            <span className="close-button" onClick={closePopup}>&times;</span>
                          </div>
                          {popupData.type === 'accept' ? (
                            <div className="popup-display-data">
                              <p>
                                Are you sure you want to accept <span>{type_data[requestType]} Edit Request</span>?
                              </p>
                              <div className="confirm-button" onClick={handleAcceptRequest}>Confirm</div>
                            </div>
                          ) : (
                            <div className="reject-popup">
                              <select
                                className="rejection-reason-dropdown"
                                id="rejection-reason"
                                value={selectedReason}
                                onChange={handleSelectChange}
                              >
                                <option value="">Choose Rejection Reason</option>
                                {Object.entries(requestData.qc_rejection_status).map(([key, value], index) => (
                                  <option key={index} value={key}>
                                    {value}
                                  </option>
                                ))}
                              </select>
                              {errorMessage && <p style={{ color: 'red', font: '10px' }}>{errorMessage}</p>}

                              <div className="confirm-button" onClick={handleRejectRequest}>Confirm</div>
                            </div>
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                </>
              )}
            </>
          )}
        </>
      )}
    </div>
  );
};

export default RetailerEdit;
