import React, { useRef, useState } from 'react';
import { Select, MenuItem, FormControl, InputLabel, Tooltip, Paper, InputBase, Button, Menu, Popover } from '@material-ui/core';
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";

const CustomDropdown = ({ options, value, onChange, label }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const inputRef = useRef(null);

  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget);
    setTimeout(() => inputRef.current?.focus(), 100); // Delay for stability
  };

  const handleClose = () => {
    setAnchorEl(null);
    setSearchTerm(""); // Reset search on close
  };

  const handleSelect = (option) => {
    onChange(option);
    handleClose();
  };

  const filteredOptions = options.filter((option) =>
    option.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <FormControl fullWidth variant="outlined" style={{ width: "300px" }}>
      <InputLabel>{label}</InputLabel>

      {/* Button to Open Dropdown */}
      {/* <div> */}

      
      <Button
  variant="outlined"
  onClick={handleOpen}
  fullWidth
  style={{ 
    display: "flex", 
    color :value ?"#000" :"#666",
    justifyContent: "space-between", 
    alignItems: "center", 
    textAlign: "left", 
    textTransform: "none",
    padding: "6px 12px" 
  }}
>
  {value || "Select an option"}
  <KeyboardArrowDownIcon style={{ marginLeft: "auto" }} /> {/* 🔽 Arrow at the end */}
</Button>
      {/* </div> */}
      {/* Custom Popover (Replaces Menu) */}
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        transformOrigin={{ vertical: "top", horizontal: "left" }}
        disableAutoFocus // Prevents popover from stealing focus
        disableEnforceFocus // Allows input field to stay focused
        PaperProps={{ style: { maxHeight: 250, width: anchorEl?.offsetWidth || 300, overflowY: "auto" } }}
      >
        {/* 🔥 Sticky Search Field */}
        <Paper style={{ 
          width: "100%", 
          padding: "4px 8px", 
          position: "sticky", 
          top: 0, 
          background: "white", 
          zIndex: 10 
        }}>
          <InputBase
            inputRef={inputRef} // Ensure input remains focused
            fullWidth
            placeholder="Search SKU..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            autoFocus
          />
        </Paper>

        {/* Filtered Options */}
        {filteredOptions.length > 0 ? (
          filteredOptions.map((option, index) => (
            <MenuItem key={index} onClick={() => handleSelect(option)}>
              <Tooltip title={option} placement="right">
                <span>{option.length > 45 ? `${option.slice(0, 45)}...` : option}</span>
              </Tooltip>
            </MenuItem>
          ))
        ) : (
          <MenuItem disabled>No options found</MenuItem>
        )}
      </Popover>
    </FormControl>
  );
};


export default CustomDropdown;
